import dayjs from "dayjs";
import { createContext } from 'react';
import { useForm } from '../utils/useForm';

const QueryContext = createContext();

export default QueryContext;


export const QueryProvider = ({ children }) => {

  const initialValues = {
    noOfMigraines: '',
    age: '',
    migraineOperator: 'gte',
    ageOperator: 'lte',
    sex: 'All',
    startDate: null,
    endDate: null,
    today: dayjs(Date.now()),
    mainOperator: 'AND',
    dynamicList: [],
  };

  const {values, setValues, handleInputChange} = useForm(initialValues);

  const contextData = {
    values: values,
    initialValues: initialValues,
    setValues: setValues,
    handleInputChange: handleInputChange,
  };

  return (
    <QueryContext.Provider value={contextData}>
      {children}
    </QueryContext.Provider>
  );
};
