import { useState, useEffect } from "react";

import useAxios from "../../utils/useAxios";
import { resObj } from "../../utils/constants";
import AutoLogout from "../../utils/useAutoLogout";

import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import {
  FormControl,
  MenuItem,
  Select,
  InputLabel,
} from "@mui/material";
import MainNavigation from "../../components/layout/MainNavigation";
import TopBar from "../../components/layout/TopBar";
import ErrorModal from "../../components/layout/ErrorModal";
import BasicCard from "../../components/layout/BasicCard";
import Loader from "../../components/layout/Loader";
import BasicPaper from "../../components/layout/BasicPaper";
import DountChart from "../../components/analytics/DountChart";
import SplineChart from "../../components/analytics/SplineChart";
import AgeBarchart from "../../components/analytics/AgeBarchart";
import SymptonsBarchart from "../../components/analytics/SymptonsBarchart";
import QuestionnaireBarChart from "../../components/analytics/QuestionnaireBarChart";
import SunburstChart from "../../components/analytics/SunburstChart";
import MigraineTypesBarchart from "../../components/analytics/MigraineTypesBarchart.js";
import FitBitChart from "../../components/analytics/FitBitChart.js";

const DashboardNewPage = () => {
  const api = useAxios();

  const dashboardValues = {
    userAuthorityList: [],
    usersWithDoctors: 0,
    usersWithoutDoctors: 0,
    migraineCountThisMonth: 0,
    migraineCountThisYear: 0,
    avgPainIntensity: 0,
    yearList: [],
    triggerList: [],
    symptomsList: [],
    ageGroupList: [],
    questionnaireTypeList: [],
    migraineTypeList: [],
    medicationData: {},
    fitBitData: {},
  };

  const [apiRes, setApiRes] = useState(resObj);
  const [dashboardData, setDashboardData] = useState(dashboardValues);
  const [selectedYear, setSelectedYear] = useState("");
  const [graphData, setGraphData] = useState([]);

  // Function to reload the page
  // const handleResize = () => {
  //   window.location.reload();
  // };

  // useEffect(() => {
  //   // Add event listener on component mount
  //   window.addEventListener("resize", handleResize);

  //   // Cleanup event listener on component unmount
  //   return () => {
  //     window.removeEventListener("resize", handleResize);
  //   };
  // }, []);

  useEffect(() => {
    getDashboardData();
  }, []);

  useEffect(() => {
    if (selectedYear) getMigraineGraph();
  }, [selectedYear]);

  const getDashboardData = async () => {
    try {
      setApiRes({ ...apiRes, loading: true });
      const response = await api.get("/api/analytics/dashboard/data/get");

      if (response.status === 200) {
        setDashboardData({
          ...dashboardData,
          userAuthorityList: response.data.user_authority_list,
          usersWithDoctors: response.data.users_with_doctors,
          usersWithoutDoctors: response.data.users_without_doctors,
          migraineCountThisMonth: response.data.migraine_count_this_month,
          migraineCountThisYear: response.data.migraine_count_this_year,
          avgPainIntensity: response.data.avg_pain_intensity,
          yearList: response.data.year_list,
          triggerList: response.data.trigger_data_list,
          symptomsList: response.data.symptoms_list,
          ageGroupList: response.data.age_group_list,
          questionnaireTypeList: response.data.questionnaire_type_list,
          migraineTypeList: response.data.migraine_type_list,
          medicationData: response.data.medication_graph_data,
          fitBitData: response.data.fitbit_analytics,
        });
        setSelectedYear(response.data?.year_list[0]);
        setApiRes({ ...apiRes, loading: false });
      }
    } catch (err) {
      setApiRes({
        ...apiRes,
        axiosError: true,
        errMsg: JSON.stringify(err.response.data),
        errHeading: "User Type Get",
        loading: false,
      });
    }
  };

  const getMigraineGraph = async () => {
    try {
      const response = await api.get("/api/analytics/migraine/graph/get", {
        params: {
          year: selectedYear,
        },
      });

      if (response.status === 200) {
        setGraphData(response.data);
      }
    } catch (err) {
      setApiRes({
        ...apiRes,
        axiosError: true,
        errMsg: JSON.stringify(err.response.data),
        errHeading: "Migraine Graph Get",
        loading: false,
      });
    }
  };

  return (
    <AutoLogout>
      <MainNavigation />
      {apiRes.loading && <Loader />}
      <div className="mainContainer">
        <TopBar title={"Dashboard"} />
        <div className="mx-5">
          <Grid container spacing={1}>
            <Grid item xs={12} sm={6} md={2.4}>
              <BasicCard
                subTitle={"Patients With Doctors"}
                content={dashboardData?.usersWithDoctors}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={2.4}>
              <BasicCard
                subTitle={"Patients Without Doctors"}
                content={dashboardData?.usersWithoutDoctors}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={2.4}>
              <BasicCard
                subTitle={"Migraine count this month"}
                content={dashboardData?.migraineCountThisMonth}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={2.4}>
              <BasicCard
                subTitle={"Migraine count this year"}
                content={dashboardData?.migraineCountThisYear}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={2.4}>
              <BasicCard
                subTitle={"Average pain intensity"}
                content={dashboardData?.avgPainIntensity}
              />
            </Grid>
          </Grid>
        </div>

        <div className="my-10 mx-5 flex lg:flex-row flex-col">
          <div className="lg:w-2/6 w-full">
            <BasicPaper width={"100%"} title="Migraine Triggers">
              {dashboardData?.triggerList?.length > 0 && (
                <DountChart data={dashboardData?.triggerList} />
              )}
            </BasicPaper>
          </div>
          <div className="flex-1">
            <BasicPaper width={"100%"}>
              <Box
                className="mx-6"
                sx={{ display: "flex", justifyContent: "space-between" }}
              >
                <h1 className="font-semibold lg:text-lg md:text-base text-xs">
                  Migraine and Therapy Count by Month
                </h1>
                <Box>
                  <FormControl size="small">
                    <InputLabel id="yearLabel">Year</InputLabel>
                    <Select
                      labelId="yearLabel"
                      id="year"
                      value={selectedYear}
                      label="Year"
                      onChange={(e) => setSelectedYear(e.target.value)}
                      sx={{ minWidth: 120 }}
                    >
                      {dashboardData.yearList.map((year, index) => (
                        <MenuItem key={index} value={year}>
                          {year}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
              </Box>

              <SplineChart data={graphData} />
            </BasicPaper>
          </div>
        </div>

        <div className="my-10 mx-5 flex lg:flex-row flex-col">
          <div className="lg:w-2/6 w-full">
            <BasicPaper width={"100%"} title="Age Group and Gender">
              {dashboardData?.ageGroupList?.length > 0 && (
                <AgeBarchart data={dashboardData?.ageGroupList} />
              )}
            </BasicPaper>
          </div>
          <div className="flex-1">
            <div></div>
            <BasicPaper width={"100%"} title="Top Symptoms">
              {dashboardData?.symptomsList?.length > 0 && (
                <SymptonsBarchart data={dashboardData?.symptomsList} />
              )}
            </BasicPaper>
          </div>
        </div>

        <div className="my-10 mx-5 flex lg:flex-row flex-col">
          <div className="lg:w-2/6 w-full">
            <BasicPaper width={"100%"} title="Average Questionnaire Score">
              {dashboardData?.questionnaireTypeList?.length > 0 && (
                <QuestionnaireBarChart data={dashboardData?.questionnaireTypeList} />
              )}
            </BasicPaper>
          </div>
          <div className="flex-1">
            <div></div>
            <BasicPaper width={"100%"} title="Migrine Types">
              {dashboardData?.migraineTypeList?.length > 0 && (
                <MigraineTypesBarchart data={dashboardData?.migraineTypeList} />
              )}
            </BasicPaper>
          </div>
        </div>

        <div className="my-10 mx-5 flex lg:flex-row flex-col">
          <div className='lg: w-full'>
            <BasicPaper width={"100%"} height={400} title="Activities">
              {Object.keys(dashboardData.fitBitData).length > 0 && (
                <FitBitChart data={dashboardData?.fitBitData} />
              )}
            </BasicPaper>
          </div>
        </div>

        <div className="my-10 mx-5 flex lg:flex-row flex-col">
          <div className='lg: w-full'>
            <BasicPaper width={"100%"} height={900} title="Medication">
              {Object.keys(dashboardData.medicationData).length > 0 && (
                <SunburstChart data={dashboardData?.medicationData} />
              )}
            </BasicPaper>
          </div>
        </div>

        <ErrorModal apiRes={apiRes} setApiRes={setApiRes} />
      </div>
    </AutoLogout>
  );
};

export default DashboardNewPage;