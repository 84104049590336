import { useContext, useState, useEffect } from 'react';

import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import AuthContext from '../../context/AuthContext';
import ErrorModal from '../../components/layout/ErrorModal';
import Loader from '../../components/layout/Loader';

import styles from "./Login.module.css";
import logo from "../../assets/images/logo.png";

const theme = createTheme();

const LoginPage = () => {

  const { loginUser, apiRes, setApiRes, showAlert } = useContext(AuthContext);

  const initialValues = { email: '', password: '' };
  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);

  const handleChange = e => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleSubmit = e => {
    e.preventDefault();
    setFormErrors(validate(formValues));
    setIsSubmit(true);
  };

  const validate = values => {
    const errors = {};
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (!values.email) {
      errors.email = 'Email is required!';
    } else if (!emailRegex.test(values.email)) {
      errors.email = 'This is not a valid email format!';
    }
    if (!values.password) {
      errors.password = 'Password is required!';
    } else if (values.password.length < 4) {
      errors.password = 'Password must be more than 4 characters!';
    }
    return errors;
  };

  useEffect(() => {
    if (Object.keys(formErrors).length === 0 && isSubmit) {
      loginUser(formValues);
    }
  }, [formErrors]);

  return(
    <div className={styles.background}>
      <ThemeProvider theme={theme}>
        <Container component="main" maxWidth="xs">
          {apiRes.loading && <Loader />}
          <CssBaseline />
          <Box
            sx={{
              marginTop: 8,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              backgroundColor: '#fcfafa',
              padding: '50px',
              borderRadius: '20px',
              width: '450px'
            }}
          >
            <img src={logo} className={styles.logo} alt='logo' />
            <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
              { showAlert && <Alert severity="error">Invalid email or password.</Alert>}
              <TextField
                margin="normal"
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
                value={formValues.email}
                onChange={handleChange}
                helperText={formErrors.email}
                error={'email' in formErrors}
              />
              <TextField
                margin="normal"
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                value={formValues.password}
                onChange={handleChange}
                helperText={formErrors.password}
                error={'password' in formErrors}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2}}
                className={styles.btnStyle}
              >
                Log In
              </Button>
              <Grid container>
                <Grid item xs>
                  <Link href="/forgot-password" variant="body2">
                    Forgot password?
                  </Link>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Container>
      </ThemeProvider>
      <ErrorModal apiRes={apiRes} setApiRes={setApiRes} />
    </div>
  );
};

export default LoginPage;