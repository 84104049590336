import { Navigate } from 'react-router-dom';
import { useContext } from 'react';
import AuthContext from '../context/AuthContext';


const TwoFactorRoute = ({ Component }) => {
  const { authTokens } = useContext(AuthContext);

  return authTokens ? <Component /> : <Navigate to='/login' />;
};

export default TwoFactorRoute;