import React, {useState, useRef, useEffect } from "react";
import * as d3 from "d3";
import CreateLegend from "./CreateLegend";

const AgeBarchart = ({ data }) => {
  const svgRef = useRef();

  const [dimensions, setDimensions] = useState({
    width: window.innerWidth,
    height: window.innerHeight
  });

  const color = d3
    .scaleOrdinal()
    .domain(["male", "female"])
    .range(["rgb(178,0,0)", "rgb(54,125,31)"]);

    useEffect(() => {
      function handleResize() {
        setDimensions({
          width: window.innerWidth,
          height: window.innerHeight
        });
      }
  
      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }, []);

    const margin = { top: 30, right: 50, bottom: 40, left: 50 };
  const width = (dimensions.width * 0.32) - margin.left - margin.right;
  const height = 350 - margin.top - margin.bottom;

  useEffect(() => {

    // const margin = { top: 30, right: 50, bottom: 40, left: 50 };
    // const width =  (window.innerWidth * 0.32) - margin.left - margin.right;
    // const height =(window.innerHeight * 0.2) - margin.top - margin.bottom;

    d3.select(svgRef.current).selectAll("*").remove();

    // const svg = d3
    //   .select(svgRef.current)
    //   .attr("width", width + margin.left + margin.right)
    //   .attr("height", height + margin.top + margin.bottom)
    //   .append("g")
    //   .attr("transform", `translate(${margin.left},${margin.top})`);

    const svg = d3.select(svgRef.current)
  .attr('viewBox', `0 0 ${width + margin.left + margin.right} ${height + margin.top + margin.bottom}`)
  .append('g')
  .attr('transform', `translate(${margin.left},${margin.top})`);


    const x0 = d3
      .scaleBand()
      .domain([...new Set(data.map((d) => d.group))])
      .rangeRound([0, width])
      .paddingInner(0.2);

    const x1 = d3
      .scaleBand()
      .domain(["Male", "Female"])
      .rangeRound([0, x0.bandwidth()])
      .padding(0.3);

    const y = d3
      .scaleLinear()
      .domain([0, d3.max(data, (d) => d.intensity)])
      .nice()
      .rangeRound([height, 0]);

    // Add gridlines
    // horizontal grid lines
    svg
      .append("g")
      .attr("class", "grid")
      .attr("transform", `translate(0,0)`)
      .call(
        d3
          .axisLeft(y)
          .ticks(5)
          .tickSize(-width) // Extend ticks across the chart width
          .tickFormat("") // Remove tick labels
      )
      .call((g) => g.select(".domain").remove()) // Remove the axis line
      .call((g) =>
        g
          .selectAll(".tick:not(:first-of-type) line")
          .attr("stroke-opacity", 0.5)
          .attr("stroke-dasharray", "2,2")
      ) // Style for grid lines
      .call((g) => g.selectAll(".tick text").remove()); // Ensure text labels are removed

    // vertical grid lines
    svg
      .append("g")
      .attr("class", "grid")
      .call(
        d3
          .axisTop(x0)
          .tickSize(height) // Extend ticks across the chart height
          .tickFormat("") // Remove tick labels
      )
      .attr("transform", `translate(0,${height})`)
      .call((g) => g.select(".domain").remove()) // Remove the axis line
      .call((g) =>
        g.attr("stroke-opacity", 0.5).attr("stroke-dasharray", "2,2")
      )
      // Style for grid lines
      .call((g) => g.selectAll(".tick text").remove()); // Ensure text labels are removed

    svg
      .append("g")
      .selectAll("g")
      .data(d3.group(data, (d) => d.group))
      .join("g")
      .attr("transform", ([age]) => `translate(${x0(age)},0)`)
      .selectAll("rect")
      .data(([, d]) => d)
      .join("rect")
      .attr("x", (d) => x1(d.gender))
      .attr("y", (d) => y(d.intensity))
      .attr("width", x1.bandwidth())
      .attr("height", (d) => y(0) - y(d.intensity))
      .attr("fill", (d) => color(d.gender));

    svg
      .append("g")
      .attr("class", "x-axis")
      .attr("transform", `translate(0,${height})`)
      .call(d3.axisBottom(x0).tickSizeOuter(0));

    svg.append("g").attr("class", "y-axis").call(d3.axisLeft(y).ticks(7));

    // Y-axis label
    svg
      .append("text")
      .attr("class", "y-axis-title")
      .attr("transform", "rotate(-90)")
      .attr("x", -height / 2)
      .attr("y", -margin.left)
      .attr("dy", "1em")
      .style("text-anchor", "middle")
      .text("Migraine Intensity");

    svg.selectAll(".y-axis text").attr("dx", "-10");
  }, [width, height, data]);

  return (
    <div>
      <svg ref={svgRef}></svg>

      <CreateLegend
        colorScale={color}
        data={data}
        displayText="gender"
        classname={"flex justify-evenly"}
      />
    </div>
  );
};

export default AgeBarchart;
