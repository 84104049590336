const CreateLegend = ({ colorScale, data , classname , displayText}) => {
  if (!data) {
    console.error("Invalid data passed to createLegend:", data);
    return;
  }

  const removeArrDuplicates = array => {
    const uniqueArr = [];
  
    for (let i = 0; i < array.length; i++) {
      let exists = uniqueArr.some(obj => obj[displayText] === array[i][displayText]);
      if (!exists) {
        uniqueArr.push(array[i]);
      }
    }
    return uniqueArr;
  };

  const uniqueArray = removeArrDuplicates(data);

  return (
    <div className={classname}>
      {uniqueArray?.map((item, index) => (
        <div
        className=""
          key={index}
          style={{
            display: "flex",
            alignItems: "center",
            marginBottom: "10px",
          }}
        >
          <svg width="20" height="20">
            <circle r="4" cx="10" cy="10" fill={colorScale(item[displayText])} />
          </svg>
          <p className="text-xs capitalize">{item[displayText]}</p>
        </div>
      ))}
    </div>
  );
};

export default CreateLegend;
