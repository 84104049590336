import PropTypes from 'prop-types';

import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import Select from '@mui/material/Select';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import { InputLabel } from '@mui/material';
import { FormControl } from '@mui/material';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import TextField from "@mui/material/TextField";

import styles from "./QueryForm.module.css";

const GroupRuleItem = ({
  rule, data, handleChangeGroupRuleDrpDwn, handleChangeGroupValue,
  filterDataList, handleGroupConditionChange, hanldeDeleteGroupRule
}) => {
  return(
    <Grid container spacing={3} style={{ marginTop: '0px'}}>
      <Grid item xs={0.5}></Grid>
      <Grid item xs={3}>
        <FormControl>
          <InputLabel id={`GroupRuleLabel - ${rule.ruleIndex + 1}`}>Rule</InputLabel>
          <Select
            labelId={`GroupRule - ${rule.ruleIndex + 1}`}
            id={`GroupRule - ${rule.ruleIndex + 1}`}
            name={`GroupRule - ${rule.ruleIndex + 1}`}
            value={rule?.name}
            label='Rule'
            onChange={e => handleChangeGroupRuleDrpDwn(data.groupIndex, rule.ruleIndex, e)}
            className={styles.filterItem}
          >
            {filterDataList.map((fdata, findex) => (
              <MenuItem key={findex} value={fdata.name}>{fdata.display_name}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      
      { filterDataList.filter(obj => obj.name === rule?.name)[0]?.conditions.length > 0 &&
        <Grid item xs={3}>
          <FormControl>
            <InputLabel id={`GroupConditionLabel - ${rule.ruleIndex + 1}`}>Condition</InputLabel>
            <Select
              labelId={`GroupCondition - ${rule.ruleIndex + 1}`}
              id={`GroupCondition - ${rule.ruleIndex + 1}`}
              name={`GroupCondition - ${rule.ruleIndex + 1}`}
              value={rule?.condition}
              label='Condition'
              onChange={e => handleGroupConditionChange(data.groupIndex, rule.ruleIndex, e)}
              className={styles.filterItem}
            >
              {filterDataList.filter(obj => 
                obj.name === rule?.name)[0]?.conditions.map((condition, vindex) => (
                  <MenuItem key={vindex} value={condition.value}>{condition.display_name}</MenuItem>
                )
              )}
            </Select>
          </FormControl>
        </Grid>
      }

      { filterDataList.filter(obj => obj.name === rule?.name)[0]?.field_type === 'JSONField' &&
        <Grid item xs={3}>
          <FormControl>
            <InputLabel id={`GroupValueLabel - ${rule.ruleIndex + 1}`}>Value</InputLabel>
            <Select
              labelId={`GroupValue - ${rule.ruleIndex + 1}`}
              id={`GroupValue - ${rule.ruleIndex + 1}`}
              name={`GroupValue - ${rule.ruleIndex + 1}`}
              value={rule?.value}
              label='Value'
              onChange={e => handleChangeGroupValue(data.groupIndex, rule.ruleIndex, 'JSONField', e)}
              className={styles.filterItem}
            >
              {filterDataList.filter(obj => 
                obj.name === rule?.name)[0]?.values.map((value, vindex) => (
                  <MenuItem key={vindex} value={value}>{value}</MenuItem>
                )
              )}
            </Select>
          </FormControl>
        </Grid>
      }

      { filterDataList.filter(obj => obj.name === rule?.name)[0]?.field_type === 'IntegerField' &&
        <Grid item xs={3}>
          <TextField 
            id={`RuleValue - ${rule.index + 1}`} 
            name={`RuleValue - ${rule.index + 1}`}
            label="Value" 
            variant="outlined" 
            type='number' 
            value={rule.value}
            onChange={e => handleChangeGroupValue(data.groupIndex, rule.ruleIndex, 'IntegerField', e)}
            className={styles.filterItem}
          />
        </Grid>
      }

      { filterDataList.filter(obj => obj.name === rule?.name)[0]?.field_type === 'DecimalField' &&
        <Grid item xs={3}>
          <TextField 
            id={`RuleValue - ${rule.index + 1}`} 
            name={`RuleValue - ${rule.index + 1}`}
            label="Value" 
            variant="outlined" 
            type='number' 
            value={rule.value}
            onChange={e => handleChangeGroupValue(data.groupIndex, rule.ruleIndex, 'DecimalField', e)}
            className={styles.filterItem}
          />
        </Grid>
      }

      { filterDataList.filter(obj => obj.name === rule?.name)[0]?.field_type === 'CharField' &&
        <Grid item xs={3}>
          <TextField 
            id={`RuleValue - ${rule.index + 1}`} 
            name={`RuleValue - ${rule.index + 1}`}
            label="Value" 
            variant="outlined" 
            type='text' 
            value={rule.value}
            onChange={e => handleChangeGroupValue(data.groupIndex, rule.ruleIndex, 'CharField', e)}
            className={styles.filterItem}
          />
        </Grid>
      }

      { filterDataList.filter(obj => obj.name === rule?.name)[0]?.field_type === 'DateTimeField' &&
        <Grid item xs={3}>
          <FormControl className={styles.filterItem}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label='Value'
                name={`RuleValue - ${rule.index + 1}`}
                value={rule.value} 
                onChange={e => handleChangeGroupValue(data.groupIndex, rule.ruleIndex, 'DateTimeField', e)} 
              />
            </LocalizationProvider>
          </FormControl>
        </Grid>
      }

      <Grid item xs={1}>
        <DeleteOutlineIcon 
          onClick={e => hanldeDeleteGroupRule(data.groupIndex, rule.ruleIndex)}
          className={styles.deleteItem}
        />
      </Grid>
    </Grid>
  );
};

GroupRuleItem.propTypes = {
  rule: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  filterDataList: PropTypes.array.isRequired,
  handleChangeGroupRuleDrpDwn: PropTypes.func.isRequired,
  handleChangeGroupValue: PropTypes.func.isRequired,
  handleGroupConditionChange: PropTypes.func.isRequired,
  hanldeDeleteGroupRule: PropTypes.func.isRequired,
};

export default GroupRuleItem;