import PropTypes from 'prop-types';

import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Divider from '@mui/material/Divider';
import { Box } from "@mui/system";

import styles from "./DiaryItem.module.css";

const DiaryItem = ({ data }) => {
  const getMedication = medication => {
    if (medication.length > 0) {
      let medicationArr = [];
      for(const item of medication) {
        medicationArr = [...medicationArr, `${item.name} - ${item.dosage}${item.unit}`];
      }
      return medicationArr.join(', ');
    } else {
      return '';
    }
  };

  return(
    <Box id={`box-${data.id}`} className={styles.box}>
      <span className={styles.dot}></span>
      <div className={styles.line}></div>
      <Grid container spacing={3} style={{ marginTop: '10px', marginLeft: '0px' }}>
        <Grid item xs={12}>
          <Typography
            component="h6" 
            variant="h6" 
            color={'text.secondary'}
          >
            <strong><i>{data.startdate} - {data.enddate}</i></strong>
          </Typography>
        </Grid>
        
        <Grid item xs={4}>
          <Typography
            color={'text.secondary'}
          >
            <i>Type: {data.type}</i>
          </Typography>
        </Grid>

        <Grid item xs={4}>
          <Typography
            color={'text.secondary'}
          >
            <i>Location: {data.location}</i>
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography
            color={'text.secondary'}
          >
            <i>Intensity: {data.intensity}</i>
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography
            color={'text.secondary'}
          >
            <i>Aura Symptoms: {data.aurasymptoms.join(', ')}</i>
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography
            color={'text.secondary'}
          >
            <i>Non-Aura Symptoms: {data.nonaurasymptoms.join(', ')}</i>
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography
            color={'text.secondary'}
          >
            <i>Triggers: {data.triggers.join(', ')}</i>
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography
            color={'text.secondary'}
          >
            <i>Medication: {getMedication(data.medication)}</i>
          </Typography>
        </Grid>
      </Grid>
      <Divider style={{marginTop: '20px', marginLeft: '6px'}} />
    </Box>
  );
};

DiaryItem.propTypes = {
  data: PropTypes.object.isRequired,
};

export default DiaryItem;