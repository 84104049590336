import React, { useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import SingleGraph from "./SingleGraph";
import { styled, createTheme, ThemeProvider } from "@mui/material/styles";
import { Paper, ToggleButtonGroup, IconButton } from "@mui/material";
import MuiToggleButton from "@mui/material/ToggleButton";
import GroupedGraph from "./GroupedGraph";
import CloseIcon from "@mui/icons-material/Close"; // Import the CloseIcon
import { red } from "@mui/material/colors";
import "./modal.css";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};

const ToggleButton = styled(MuiToggleButton)(({ selectedcolor }) => ({
  "&.Mui-selected, &.Mui-selected:hover": {
    color: "white",
    backgroundColor: selectedcolor,
  },
}));

const theme = createTheme({
  palette: {
    text: {
      primary: "#00ff00",
    },
  },
});

function ChildModal({
  data1,
  showGroupedVisualization,
  showSingleVisualization,
  openchild,
  close,
  alignment,
}) {
  return (
    <React.Fragment>
      <Modal
        open={openchild}
        onClose={close}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            "& > :not(style)": {
              m: 1,
              width: `90vw`,
            },
          }}
        >
          <Paper elevation={5}>
            <div className="close-button">
              <div style={{cursor:'pointer'}}>
                <CloseIcon sx={{ color: red[500] }} onClick={close} />
              </div>

              {showSingleVisualization && (
                <SingleGraph alignment={alignment} rawData={data1} />
              )}
              {showGroupedVisualization && (
                <GroupedGraph alignment={alignment} rawData={data1} />
              )}
            </div>
          </Paper>
        </Box>
      </Modal>
    </React.Fragment>
  );
}

export default function NestedModal({ opens, close, data }) {
  const [showSingleVisualization, setShowSingleVisualization] = useState(true);
  const [showGroupedVisualization, setShowGroupedVisualization] =
    useState(false);

  const [isChildModalOpen, setChildModalOpen] = useState(false);

  const handleCloseChildModal = () => {
    setChildModalOpen(false);
  };

  const handleSingleVisualizationClick = () => {
    setChildModalOpen(true);
    setShowSingleVisualization(true);
    setShowGroupedVisualization(false);
  };

  const handleGroupedVisualizationClick = () => {
    setChildModalOpen(true);
    setShowSingleVisualization(false);
    setShowGroupedVisualization(true);
  };

  const [alignment, setAlignment] = React.useState("line");

  const handleChange = (event, newAlignment) => {
    setAlignment(newAlignment);
  };
  return (
    <div>
      <Modal
        open={opens}
        onClose={close}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box sx={{ ...style, width: 400 }}>
          <IconButton
            edge="end"
            color="inherit"
            onClick={close} // Close the modal
            sx={{
              position: "absolute",
              top: 10, // Adjust the top position as needed
              right: 10, // Adjust the right position as needed
            }}
          >
            <CloseIcon sx={{ color: red[500] }} />
          </IconButton>
          <ToggleButtonGroup
            value={alignment}
            exclusive
            onChange={handleChange}
            aria-label="Platform"
          >
            <ToggleButton value="line" selectedcolor="#d85c24">
              Line Chart
            </ToggleButton>
            <ToggleButton value="bar" selectedcolor="#d85c24">
              Bar Chart
            </ToggleButton>
          </ToggleButtonGroup>
          <Button onClick={handleSingleVisualizationClick}>
            Single Visualization
          </Button>
          <Button onClick={handleGroupedVisualizationClick}>
            Grouped Visualization
          </Button>
          <ChildModal
            showGroupedVisualization={showGroupedVisualization}
            showSingleVisualization={showSingleVisualization}
            data1={data}
            openchild={isChildModalOpen}
            close={handleCloseChildModal}
            alignment={alignment}
          />
        </Box>
      </Modal>
    </div>
  );
}
