// LineChart.js
import React, { useEffect, useRef } from "react";
import * as d3 from "d3";

const LineChart = ({ data, selectedXAxis, selectedGrouping }) => {
  const svgRef = useRef();

  const juneData = data.filter((record) => {
    const day = new Date(record.startDate);
    return day.getMonth() === 5; // Months are 0-indexed, so June is 5
  });

  const sorted = juneData.slice().sort((a, b) => {
    return new Date(a.startDate) - new Date(b.startDate);
  });
  const sortedage = data.slice().sort((a, b) => {
    return (a.age) - (b.age);
  });

  useEffect(() => {
    const margin = { top: 30, right: 250, bottom: 60, left: 50 };
    const width = 1000 - margin.left - margin.right;
    const height = 600 - margin.top - margin.bottom;

    const svg = d3
      .select(svgRef.current)
      .attr("width", width + margin.left + margin.right)
      .attr("height", height + margin.top + margin.bottom)
      .append("g")
      .attr("transform", `translate(${margin.left},${margin.top})`);

    // Group data by selectedGrouping
    const groupedData = d3.groups(selectedXAxis ==='startDate' ? sorted :sortedage,
      (d) => d[selectedGrouping],
      (d) => d[selectedXAxis]
    );

    // Calculate the intensity sum for each selectedGrouping and each day
    const intensityData = groupedData
      .map(([selectedGroups, selectedGroupingData]) => {
        const dailyIntensity = selectedGroupingData.map(([selectedX, selectedXAxisData]) => ({
          [selectedXAxis]: selectedX,
          intensitySum: d3.sum(selectedXAxisData, (d) => d.intensity),
          [selectedGrouping]: selectedGroups,
        }));
        return dailyIntensity;
      })
      .flat();

    const groupedDatas = d3.group(intensityData, (d) => d[selectedGrouping]);

    let x;

    if (selectedXAxis === "startDate") {
      x = d3
        .scaleTime()
        .domain(d3.extent(intensityData, (d) => new Date(d[selectedXAxis])))
        .range([0, width]);
    } else {
      x = d3
        .scaleLinear()
        .domain([d3.min(intensityData, (d) => d[selectedXAxis]),d3.max(intensityData, (d) => (d[selectedXAxis]))])
        .nice()
        .range([0, width]);
    }

    const y = d3
      .scaleLinear()
      .domain([0, d3.max(intensityData, (d) => d.intensitySum)])
      .nice()
      .range([height, 0]);

    const color = d3
      .scaleOrdinal()
      .domain(groupedDatas.keys())
      .range(d3.schemeCategory10);

    const line = d3
      .line()
      .x((d) => x(selectedXAxis === 'startDate' ? new Date(d[selectedXAxis]): d[selectedXAxis]))
      .y((d) => y(d.intensitySum));

    groupedDatas.forEach((values, selectedGrouping) => {
      svg
        .append("path")
        .datum(values)
        .attr("fill", "none")
        .attr("stroke", color(selectedGrouping))
        .attr("stroke-width", 2)
        .attr("d", line);

      // Add text labels at the end of each line
      svg
        .append("text")
        .attr("fill", color(selectedGrouping))
        .attr("paint-order", "stroke")
        .attr("stroke", "white")
        .attr("stroke-width", 3)
        .attr("x", x(selectedXAxis === 'startDate' ? new Date(values[values.length - 1][selectedXAxis]):(values[values.length - 1][selectedXAxis])))
        .attr("y", y(values[values.length - 1].intensitySum))
        .attr("dy", "0.35em")
        .text(selectedGrouping);
    });


    svg
      .append("g")
      .attr("class", "x-axis")
      .attr("transform", `translate(0,${height})`)
      .call(d3.axisBottom(x));

    svg.append("g").attr("class", "y-axis").call(d3.axisLeft(y));

    svg
      .append("text")
      .attr("class", "y-axis-title")
      .attr("transform", "rotate(-90)")
      .attr("x", -height / 2)
      .attr("y", -margin.left) // Adjust this to your needs
      .attr("dy", "1em")
      .style("text-anchor", "middle")
      .text("Migraine Intensity");

    svg
      .append("text")
      .attr("class", "x-axis-title")
      .attr("transform", `translate(${width / 2},${height + 45})`)
      .style("text-anchor", "middle")
      .text(`${selectedXAxis.charAt(0).toUpperCase() + selectedXAxis.slice(1)} Of Migraine(June) and Their ${selectedGrouping}`);

    svg
      .selectAll(".x-axis text")
      .attr("transform", "translate(0,7)rotate(-45)");

    svg.selectAll(".y-axis text").attr("dx", "-10");

    // Cleanup
    return () => {
      svg.selectAll("*").remove();
    };
  }, [data, selectedGrouping, selectedXAxis, sorted, sortedage]);

  return <svg ref={svgRef}></svg>;
};

export default LineChart;
