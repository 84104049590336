import PropTypes from 'prop-types';

import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Divider from '@mui/material/Divider';
import { Box } from "@mui/system";

import styles from "./DiaryItem.module.css";

const MeasurementItem = ({ data }) => {
  return(
    <Box id={`box-${data.id}`} className={styles.box}>
      <span className={styles.dot}></span>
      <div className={styles.line}></div>

      <Grid container spacing={3} style={{ marginTop: '10px', marginLeft: '0px' }}>
        <Grid item xs={12}>
          <Typography
            component="h6" 
            variant="h6" 
            color={'text.secondary'}
          >
            <strong><i>{data.createdat}</i></strong>
          </Typography>
        </Grid>
      </Grid>
        
      <Grid container spacing={3} style={{ marginTop: '0px', marginLeft: '0px' }}>
        <Grid item xs={4}>
          <Typography
            color={'text.secondary'}
          >
            <i>Type: {data.type}</i>
          </Typography>
        </Grid>

        <Grid item xs={3}>
          <Typography
            color={'text.secondary'}
          >
            <i>Source: {data.source}</i>
          </Typography>
        </Grid>

        <Grid item xs={11}>
          <Typography
            color={'text.secondary'}
          >
            <i>Value: {data.value}</i>
          </Typography>
        </Grid>
      </Grid>
      
      <Divider style={{marginTop: '20px', marginLeft: '6px'}} />
    </Box>
  );
};

MeasurementItem.propTypes = {
  data: PropTypes.object.isRequired,
};

export default MeasurementItem;