import PropTypes from 'prop-types';

import React, { useRef, useEffect } from "react";
import * as d3 from "d3";

const SunburstChart = ({ data }) => {
  const svgRef = useRef(null);

  useEffect(() => {
    // Set up dimensions and margin for the SVG
    const width = 800;
    const height = 800;
    const radius = Math.min(width, height) / 2;

    // Create the partition layout and arc generator
    const partition = d3.partition().size([2 * Math.PI, radius]);
    const arc = d3
      .arc()
      .startAngle(d => d.x0)
      .endAngle(d => d.x1)
      .innerRadius(d => d.y0)
      .outerRadius(d => d.y1);

    // Set up color scale
    const color = d3.scaleOrdinal(d3.schemeCategory10);

    // Create the SVG container
    const svg = d3
      .select(svgRef.current)
      .attr("width", width)
      .attr("height", height)
      .append("g")
      .attr("transform", `translate(${width / 2}, ${height / 2})`);

    // Convert the data into a hierarchy and apply the partition layout
    const root = d3.hierarchy(data).sum(d => d.count);
    partition(root);

    const tooltip = d3.select("body").append("div")
      .attr("class", "tooltip")
      .style("position", "absolute")
      .style("background-color", "rgba(0, 0, 0, 0.7)")
      .style("color", "white")
      .style("padding", "10px")
      .style("border-radius", "5px")
      .style("visibility", "hidden");

    // Append the slices (arc paths) to the SVG
    svg
      .selectAll("path")
      .data(root.descendants())
      .enter()
      .append("path")
      .attr("d", arc)
      .style("fill", d => color(d.depth)) // Use color scale for each depth level
      .style("stroke", "#fff")
      .style("stroke-width", "1px")
      .on("mouseover", (event, d) => {
        // On hover, show the tooltip and update its content
        tooltip
          .html(`
            <strong>${d.data.name}</strong><br/>
            Count: ${d.data.count !== undefined ? d.data.count : d.children.length}<br/>
            ${d.data.dosage !== undefined ?  `Avg Dosage: ${d.data.dosage}` : ""}
          `)
          .style("visibility", "visible")
          .style("top", `${event.pageY + 10}px`) // Position below the cursor
          .style("left", `${event.pageX + 10}px`); // Position to the right of the cursor

        d3.select(event.currentTarget)
          .transition()
          .duration(200)
          .style("opacity", 0.7); // Slightly fade the segment on hover
      })
      .on("mouseout", () => {
        // Hide the tooltip when mouse leaves
        tooltip.style("visibility", "hidden");

        d3.select(event.currentTarget)
          .transition()
          .duration(200)
          .style("opacity", 1); // Reset opacity
      });

    // Add labels (optional)
    svg
      .selectAll("text")
      .data(root.descendants())
      .enter()
      .append("text")
      .attr("transform", d => {
        const [x, y] = arc.centroid(d);
        return `translate(${x}, ${y})`;
      })
      .attr("text-anchor", "middle")
      .attr("dy", "0.35em")
      .style("fill", "#fff")
      .text(d => (d.data.name ? d.data.name : ""));

  }, []);

  return (
    <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
      <svg ref={svgRef}></svg>
    </div>
  );
};

SunburstChart.propTypes = {
  data: PropTypes.object.isRequired,
};

export default SunburstChart;
