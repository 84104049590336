import PropTypes from 'prop-types';

import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";

import { Form } from "../../utils/useForm";

const UserProfileForm = ({ 
  values, handleInputChange, submitForm,formErrors
}) => {

  return(
    <>
      <Form onSubmit={submitForm} style={{padding: '20px'}}>
      
        <Grid container spacing={1}>
          <Grid item xs={12} md={6}>
            <TextField
              varient="outlined"
              label="First Name"
              name='firstName'
              value={values.firstName}
              disabled
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <TextField
              varient="outlined"
              label="Last Name"
              name='lastName'
              value={values.lastName}
              disabled
            />
          </Grid>
        </Grid>

        <Grid container spacing={1}>
          <Grid item xs={12} md={6}>
            <TextField
              varient="outlined"
              label="E-mail"
              name='email'
              value={values.email}
              disabled
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <TextField
              varient="outlined"
              label="Role"
              name='roleName'
              value={values.roleName}
              disabled
            />
          </Grid>
        </Grid>

        <Grid container spacing={1}>
          <Grid item xs={12} md={6}>
            <TextField
              varient="outlined"
              label="Password"
              name='password'
              type="password"
              value={values.password}
              onChange={handleInputChange('password')}
              style={{backgroundColor: 'white'}}
              helperText={formErrors.password}
              error={'password' in formErrors}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <TextField
              varient="outlined"
              label="Confirm Password"
              name='confirmPassword'
              type="password"
              value={values.confirmPassword}
              onChange={handleInputChange('confirmPassword')}
              style={{backgroundColor: 'white'}}
              helperText={formErrors.confirmPassword}
              error={'confirmPassword' in formErrors}
            />
          </Grid>
        </Grid>

        <Grid container spacing={1} style={{ marginLeft: 1 }}>
          <Grid item xs={12}>
            <Button 
              variant='contained' 
              type='submit'
              className="taraPrimaryBtn"
            >
              Submit
            </Button>
          </Grid>
        </Grid>
      </Form>
    </>
  );
};

UserProfileForm.propTypes = {
  values: PropTypes.object.isRequired,
  handleInputChange: PropTypes.func.isRequired,
  submitForm: PropTypes.func.isRequired,
  formErrors: PropTypes.object.isRequired,
};

export default UserProfileForm;