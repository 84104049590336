import React, { useEffect, useRef } from "react";
import * as d3 from "d3";
import CreateLegend from "./CreateLegend";

const SplineChart = ({ data }) => {
  const ref = useRef();

  const categories = Array.from(new Set(data.map((d) => d.category)));

  const color = d3.scaleOrdinal().domain(categories).range(d3.schemeCategory10);

  useEffect(() => {
    if (!data || data.length === 0) return;

    // const width = 580;
    const height = 400;
    let width = window.innerWidth * 0.8;  // 80% of the window width
    // let height = window.innerHeight * 0.6; // 40% of the window height
    const marginTop = 20;
    const marginRight = 50;
    const marginBottom = 30;
    const marginLeft = 50;

    // Create scales
    const x = d3
      .scalePoint()
      .domain(data.map((d) => d.label).filter((v, i, a) => a.indexOf(v) === i)) // Unique labels
      .range([marginLeft, width - marginRight]);

    const y = d3
      .scaleLinear()
      .domain([0, d3.max(data, (d) => d.value)])
      .nice()
      .range([height - marginBottom, marginTop]);

    // Clean up existing SVG to re-render
    d3.select(ref.current).select("svg").remove();

    const svg = d3
      .select(ref.current)
      .append("svg")
      .attr("width", width)
      .attr("height", height)
      .attr("viewBox", [0, 0, width, height])
      .attr(
        "style",
        "max-width: 100%; height: auto; overflow: visible; font: 10px sans-serif;"
      );

    // Add axes
    svg
      .append("g")
      .attr("transform", `translate(10,${height - marginBottom})`)
      .call(d3.axisBottom(x)); // Format as "Month Year"

    svg
      .append("g")
      .attr("transform", `translate(${marginLeft},0)`)
      .call(d3.axisLeft(y).ticks(5))
      .call((g) => g.select(".domain").remove());

    // Add gridlines
    // horizontal grid lines
    svg
      .append("g")
      .attr("class", "grid")
      .attr("transform", `translate(${marginLeft},0)`)
      .call(
        d3
          .axisLeft(y)
          .ticks(5)
          .tickSize(-width + marginLeft + marginRight) // Extend ticks across the chart width
          .tickFormat("") // Remove tick labels
      )
      .call((g) => g.select(".domain").remove()) // Remove the axis line
      .call((g) =>
        g
          .selectAll(".tick:not(:first-of-type) line")
          .attr("stroke-opacity", 0.5)
          .attr("stroke-dasharray", "2,2")
      ) // Style for grid lines
      .call((g) => g.selectAll(".tick text").remove()); // Ensure text labels are removed

    // vertical grid lines
    svg
      .append("g")
      .attr("class", "grid")
      .call(
        d3
          .axisTop(x)
          .tickSize(height - marginTop - marginBottom) // Extend ticks across the chart height
          .tickFormat("") // Remove tick labels
      )
      .attr("transform", `translate(10,${height - marginBottom})`)
      .call((g) => g.select(".domain").remove()) // Remove the axis line
      .call((g) =>
        g
          .selectAll(".tick:not(:first-of-type) line")
          .attr("stroke-opacity", 0.5)
          .attr("stroke-dasharray", "2,2")
      ) // Style for grid lines
      .call((g) => g.selectAll(".tick text").remove()); // Ensure text labels are removed

    // Line generator
    const line = d3
      .line()
      //   .defined(d => !isNaN(d.value))
      .x((d) => x(d.label))
      .y((d) => y(d.value))
      .curve(d3.curveMonotoneX);

    // Group data by division
    const dataByDivision = d3.group(data, (d) => d.category);

    svg
      .append("g")
      .attr("transform", `translate(10,0)`)
      .selectAll("path")
      .data(dataByDivision)
      .join("path")
      .attr("fill", "none")
      .attr("stroke", (d) => color(d[0]))
      .attr("stroke-width", 2)
      .attr("d", (d) => line(d[1]));

    // Append a group for each dot
    const dots = svg
      .append("g")
      .attr("transform", `translate(10)`)
      .selectAll("dot")
      .data(data)
      .enter()
      .append("g");

    // Append a circle in each group
    dots
      .append("circle")
      .attr("cx", (d) => x(d.label))
      .attr("cy", (d) => y(d.value))
      .attr("r", 2.5)
      .style("fill", (d) => color(d.category));

    // Append a text element in each group, initially hidden
    dots
      .append("text")
      .text((d) => d.category)
      .attr("x", (d) => x(d.label))
      .attr("y", (d) => y(d.value) - 10)
      .attr("text-anchor", "middle")
      .style("display", "none"); // Initially hide the text

    // Show text on mouseover
    dots
      .on("mouseover", function (event, d) {
        d3.select(this).select("text").style("display", null);
      })
      .on("mouseout", function (event, d) {
        d3.select(this).select("text").style("display", "none");
      });
   
  }, [data]);

  return (
    <>
      <div ref={ref} />

      <CreateLegend
        colorScale={color}
        data={data}
        displayText="category"
        classname={"flex justify-evenly"}
      />
    </>
  );
};

export default SplineChart;
