import { useState, useContext, useEffect, useRef } from "react";

import PermissionContext from "../../context/PermissionContext";
import QueryContext from "../../context/QueryContext";
import useAxios from "../../utils/useAxios";
import AutoLogout from "../../utils/useAutoLogout";
import { resObj } from "../../utils/constants";

import MainNavigation from "../../components/layout/MainNavigation";
import Loader from "../../components/layout/Loader";
import TopBarWithBack from "../../components/layout/TopBarWithBack";
import QueryUserForm from "../../components/analytics/QueryUserForm";
import ErrorModal from "../../components/layout/ErrorModal";

const QueryDetailPage = () => {
  const api = useAxios();

  const { permisionsList, checkUserPermission } = useContext(PermissionContext);

  useEffect(() => {
    if(permisionsList.length > 0) checkUserPermission(['Analytics-Querying']);
  }, [permisionsList]);

  const filterValues  = useContext(QueryContext).values;

  const docUrIArr = window.location.href.split('/');
  const userId = docUrIArr[docUrIArr.length - 1];
  const backURL = '/querying';

  const initialValues = {
    pid: '',
    age: '',
    sex: '',
    race: '',
    occupation: '',
    weight: '',
    height: '',
    educationlevel: '',
    martialstatus: '',
    smoking: '',
    diaryList: [],
    therapyList: [],
    questionnaireList: [],
    measurementList: [],
  };
  const [values, setValues] = useState(initialValues);
  const [apiRes, setApiRes] = useState(resObj);
  const [tabValue, setTabValue] = useState('Migraine');

  const showObj = {
    diary: false, 
    therapy: false, 
    questionnaire: false,
    measurement: false,
  };
  const showAll = useRef(showObj);

  useEffect(() => {
    getQueryResult();
  }, [])

  const getQueryResult = async () => {
    try {
      setApiRes({...apiRes, loading: true});
      const response = await api.get('/api/analytics/query/user/details/get', {
        params: { 
          user_id: userId,
          start_date: filterValues?.startDate,
          end_date: filterValues?.endDate,
          main_operator: filterValues?.mainOperator,
          diary_show_all: showAll.current.diary,
          therapy_show_all: showAll.current.therapy,
          questionnaire_show_all: showAll.current.questionnaire,
          measurement_show_all: showAll.current.measurement,
          dynamic_list: JSON.stringify(filterValues?.dynamicList),
        }
      });
      
      if (response.status === 200) {
        const data = response.data;
        setValues({
          ...values,
          pid: data.pid,
          age: data.age,
          sex: data.sex,
          race: data.race,
          occupation: data.occupation,
          weight: data.weight,
          height: data.height,
          educationlevel: data.educationlevel,
          martialstatus: data.martialstatus,
          smoking: data.smoking,
          diaryList: data.diary_list,
          therapyList: data.therapy_list,
          questionnaireList: data.questionnaire_list,
          measurementList: data.measurement_list,
        });
        setApiRes({...apiRes, loading: false});
      }
    } catch (err){
      setApiRes({
        ...apiRes,
        axiosError: true,
        errMsg: JSON.stringify(err.response.data),
        errHeading: 'Query Results Get',
        loading: false,
      });
    }
  };

  const toggleDiaryShowAll = () => {
    showAll.current.diary = !showAll.current.diary;
    getQueryResult();
  };

  const toggleTherapyShowAll = () => {
    showAll.current.therapy = !showAll.current.therapy;
    getQueryResult();
  };

  const toggleQuestionnaireShowAll = () => {
    showAll.current.questionnaire = !showAll.current.questionnaire;
    getQueryResult();
  };

  const toggleMeasurementShowAll = () => {
    showAll.current.measurement = !showAll.current.measurement;
    getQueryResult();
  };

  return(
    <AutoLogout>
      <MainNavigation />
      {apiRes.loading && <Loader />}
      <div className="mainContainer">
        <TopBarWithBack title={values.pid} backURL={backURL} />

        <QueryUserForm 
          values={values} 
          showAll={showAll} 
          toggleDiaryShowAll={toggleDiaryShowAll} 
          toggleTherapyShowAll={toggleTherapyShowAll} 
          toggleQuestionnaireShowAll={toggleQuestionnaireShowAll} 
          toggleMeasurementShowAll={toggleMeasurementShowAll} 
          tabValue={tabValue} 
          setTabValue={setTabValue} 
        />
        
        <ErrorModal apiRes={apiRes} setApiRes={setApiRes} />
      </div>
    </AutoLogout>
  );
};

export default QueryDetailPage;