import { createContext, useState, useEffect } from 'react';
import jwt_decode from 'jwt-decode';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { baseURL, resObj } from '../utils/constants';


const AuthContext = createContext();

export default AuthContext;

export const AuthProvider = ({ children }) => {

  const savedAuthTokens = localStorage.getItem('authTokens');
  const [authTokens, setAuthTokens] = useState(() => savedAuthTokens ? JSON.parse(savedAuthTokens) : null);
  const [user, setUser] = useState(() => savedAuthTokens ? jwt_decode(savedAuthTokens) : null);
  const [loading, setLoading] = useState(true);
  const [showAlert, setShowAlert] = useState(false);

  const navigate = useNavigate();

  const [apiRes, setApiRes] = useState(resObj);

  const loginUser = async values  => {
    setApiRes({...apiRes, loading: true});

    try {
      const response = await axios.post(`${baseURL}/api/token/`, {
        'username': values?.email, 
        'password': values?.password,
      });
      
      setApiRes({...apiRes, loading: false});
      
      const data = response.data;
      const jwt_data = jwt_decode(data.access);
      setAuthTokens(data);
      setShowAlert(false);
      if (jwt_data.show_email_verification) {
        navigate('/emailverification');
      } else {
        setUser(jwt_data);
        localStorage.setItem('authTokens', JSON.stringify(data));
        navigate('/');
      }
    } catch (err) {
      if (err.response.status === 401) {
        setShowAlert(true);
        setApiRes({...apiRes, loading: false});
      } else {
        setApiRes({
          ...apiRes,
          axiosError: true,
          errMsg: JSON.stringify(err.response.data),
          errHeading: 'Sign In',
        });
      }
    }
  };

  const logoutUser = () => {
    setAuthTokens(null);
    setUser(null);
    localStorage.removeItem('authTokens');
  };

  const contextData = {
    user: user,
    authTokens: authTokens,
    setUser: setUser,
    setAuthTokens: setAuthTokens,
    loginUser: loginUser,
    logoutUser: logoutUser,
    apiRes: apiRes,
    setApiRes: setApiRes,
    showAlert: showAlert,
  };

  useEffect(() => {
    if (authTokens) {
      setUser(jwt_decode(authTokens.access));
    }
    setLoading(false);
  }, [authTokens, loading])

  return (
    <AuthContext.Provider value={contextData}>
      {loading ? null : children}
    </AuthContext.Provider>
  );
};