import { useEffect, useContext, useState } from "react";

import useAxios from "../../utils/useAxios";
import PermissionContext from "../../context/PermissionContext";
import QueryContext from "../../context/QueryContext";

import MainNavigation from "../../components/layout/MainNavigation";
import TopBar from "../../components/layout/TopBar";
import Loader from "../../components/layout/Loader";
import ErrorModal from "../../components/layout/ErrorModal";
import Graph from "../../components/analytics/Graph";

const DataVisualisationPage = () => {
  const api = useAxios();
  const { apiRes, setApiRes } = useContext(PermissionContext);
  const filterValues = useContext(QueryContext).values;
  const [showSingleVisualization, setShowSingleVisualization] = useState(true);
  const [showGroupedVisualization, setShowGroupedVisualization] =
    useState(false);
  const [graphData, setGraphData] = useState([]);

  const handleSingleVisualizationClick = () => {
    setShowSingleVisualization(true);
    setShowGroupedVisualization(false);
  };

  const handleGroupedVisualizationClick = () => {
    setShowSingleVisualization(false);
    setShowGroupedVisualization(true);
  };

  useEffect(() => {
    getGraphData();
  }, []);

  const getGraphData = async () => {
    try {
      setApiRes({ ...apiRes, loading: true });

      const response = await api.get("/api/analytics/graph/data/get", {
        params: {
          no_of_migraines: +filterValues?.noOfMigraines,
          migraine_operator: filterValues?.migraineOperator,
          start_date: filterValues?.startDate,
          end_date: filterValues?.endDate,
          age: filterValues?.age,
          age_operator: filterValues?.ageOperator,
          sex: filterValues?.sex,
          main_operator: filterValues?.mainOperator,
          dynamic_list: JSON.stringify(filterValues?.dynamicList),
        },
      });

      if (response.status === 200) {
        setGraphData(response.data);
        setApiRes({
          ...apiRes,
          loading: false,
          showAlert: true,
          successMsg: "Graph data get successfully",
        });
      }
    } catch (err) {
      setApiRes({
        ...apiRes,
        axiosError: true,
        errMsg: JSON.stringify(err.response.data),
        errHeading: "Graph Data Get",
        loading: false,
      });
    }
  };
  
  return (
    <>
      <MainNavigation />
      {apiRes.loading && <Loader />}
      <div className="mainContainer">
        <TopBar title={"Data Visualisation"} />
        <Graph
          data={graphData}
          showGroupedVisualization={showGroupedVisualization}
          showSingleVisualization={showSingleVisualization}
        />
        <ErrorModal apiRes={apiRes} setApiRes={setApiRes} />
      </div>
    </>
  )
};

export default DataVisualisationPage;
