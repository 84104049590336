import PropTypes from 'prop-types';

import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import Select from '@mui/material/Select';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import TextField from "@mui/material/TextField";

import styles from "./QueryForm.module.css";

const RuleItem = ({
  data, filterDataList, handleChangeRuleDrpDwn, handleChangeValue,
  handleChangeCondition, hanldeDeleteRule,
}) => {
  return(
    <Grid container spacing={3} style={{ marginTop: '0px'}}>
      <Grid item xs={3}>
        <FormControl>
          <InputLabel id={`Rule - ${data.index + 1}`}>Rule</InputLabel>
          <Select
            labelId={`Rule - ${data.index + 1}`}
            id={`Rule - ${data.index + 1}`}
            name={`Rule - ${data.index + 1}`}
            value={data?.name}
            label='Rule'
            onChange={e => handleChangeRuleDrpDwn(data?.index, e)}
            className={styles.filterItem}
          >
            {filterDataList.map((fdata, findex) => (
              <MenuItem key={findex} value={fdata.name}>{fdata.display_name}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>

      { filterDataList.filter(obj => obj.name === data?.name)[0]?.conditions.length > 0 &&
        <Grid item xs={3}>
          <FormControl>
            <InputLabel id={`GroupConditionLabel - ${data.index + 1}`}>Condition</InputLabel>
            <Select
              labelId={`GroupCondition - ${data.index + 1}`}
              id={`GroupCondition - ${data.index + 1}`}
              name={`GroupCondition - ${data.index + 1}`}
              value={data?.condition}
              label='Condition'
              onChange={e => handleChangeCondition(data.index, e)}
              className={styles.filterItem}
            >
              {filterDataList.filter(obj => 
                obj.name === data?.name)[0]?.conditions.map((condition, vindex) => (
                  <MenuItem key={vindex} value={condition.value}>{condition.display_name}</MenuItem>
                )
              )}
            </Select>
          </FormControl>
        </Grid>
      }
      
      { filterDataList.filter(obj => obj.name === data?.name)[0]?.field_type === 'JSONField' &&
        <Grid item xs={3}>
          <FormControl>
            <InputLabel id={`RuleValue - ${data.index + 1}`}>Value</InputLabel>
            <Select
              labelId={`RuleValue - ${data.index + 1}`}
              id={`RuleValue - ${data.index + 1}`}
              name={`RuleValue - ${data.index + 1}`}
              value={data.value}
              label='Value'
              onChange={e => handleChangeValue(data?.index, 'JSONField', e)}
              className={styles.filterItem}
            >
              {filterDataList.filter(obj => 
                obj.name === data.name)[0]?.values.map((value, vindex) => (
                  <MenuItem key={vindex} value={value}>{value}</MenuItem>
                )
              )}
            </Select>
          </FormControl>
        </Grid>
      }

      { filterDataList.filter(obj => obj.name === data?.name)[0]?.field_type === 'IntegerField' &&
        <Grid item xs={3}>
          <TextField 
            id={`RuleValue - ${data.index + 1}`} 
            name={`RuleValue - ${data.index + 1}`}
            label="Value" 
            variant="outlined" 
            type='number' 
            value={data.value}
            onChange={e => handleChangeValue(data?.index, 'IntegerField', e)}
            className={styles.filterItem}
          />
        </Grid>
      }

      { filterDataList.filter(obj => obj.name === data?.name)[0]?.field_type === 'DecimalField' &&
        <Grid item xs={3}>
          <TextField 
            id={`RuleValue - ${data.index + 1}`} 
            name={`RuleValue - ${data.index + 1}`}
            label="Value" 
            variant="outlined" 
            type='number' 
            value={data.value}
            onChange={e => handleChangeValue(data?.index, 'DecimalField', e)}
            className={styles.filterItem}
          />
        </Grid>
      }

      { filterDataList.filter(obj => obj.name === data?.name)[0]?.field_type === 'CharField' &&
        <Grid item xs={3}>
          <TextField 
            id={`RuleValue - ${data.index + 1}`} 
            name={`RuleValue - ${data.index + 1}`}
            label="Value" 
            variant="outlined" 
            type='text' 
            value={data.value}
            onChange={e => handleChangeValue(data?.index, 'CharField', e)}
            className={styles.filterItem}
          />
        </Grid>
      }

      { filterDataList.filter(obj => obj.name === data?.name)[0]?.field_type === 'DateTimeField' &&
        <Grid item xs={3}>
          <FormControl className={styles.filterItem}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label='Value'
                name={`RuleValue - ${data.index + 1}`}
                value={data.value} 
                onChange={e => handleChangeValue(data?.index, 'DateTimeField', e)} 
              />
            </LocalizationProvider>
          </FormControl>
        </Grid>
      }

      <Grid item xs={1}>
        <DeleteOutlineIcon 
          onClick={e => hanldeDeleteRule(data.index)}
          className={styles.deleteItem}
        />
      </Grid>
    </Grid>
  );
};

RuleItem.propTypes = {
  data: PropTypes.object.isRequired,
  filterDataList: PropTypes.array.isRequired,
  handleChangeRuleDrpDwn: PropTypes.func.isRequired,
  handleChangeValue: PropTypes.func.isRequired,
  handleChangeCondition: PropTypes.func.isRequired,
  hanldeDeleteRule: PropTypes.func.isRequired,
};

export default RuleItem;