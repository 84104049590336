import { useContext } from "react";

import NotificationContext from "../../context/NotificationContext";

import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import SearchBar from './SearchBar';
import NotificationsIcon from '@mui/icons-material/Notifications';
import Badge from '@mui/material/Badge';

const ListHeader = props => {
  const { notificationCount, handleNotificationClick } = useContext(NotificationContext);

  return(
    <Grid container spacing={1} style={{ marginTop: 2 }}>
      <Grid item xs={12} sm={3.5} md={4.75} lg={5} xl={6.25}>
        <Typography 
          component="h4" 
          variant="h4" 
          color={'text.secondary'} 
        >
          <strong>{props.title}</strong>
        </Typography>
      </Grid>

      <Grid item xs={12} sm={5} md={5} lg={5} xl={4.5}>
        <SearchBar search={props.search} pageData={props.pageData} />
      </Grid>

      {props.hasCreatePermission &&
        <Grid item xs={6} sm={2} md={1.5} lg={1.25} xl={0.75}>
          <Button
            variant="contained" 
            color="success"
            onClick={props.handleClick}
          >
            Create
          </Button>
        </Grid>
      }

      <Grid item xs={6} sm={1.5} md={0.75} lg={0.75} xl={0.5}>
        <Box 
          justifyContent="center"
          alignItems="center"
          sx={{
            marginTop: -1,
          }}
        >
          <IconButton
            size="large"
            aria-label="show 17 new notifications"
            color="inherit"
            onClick={handleNotificationClick}
          >
            <Badge badgeContent={notificationCount} color="error">
              <NotificationsIcon 
                color="action" 
                style={{
                  width: 30,
                  height: 30,
                }}
              />
            </Badge>
          </IconButton>
        </Box>
      </Grid>
    </Grid>
  );
};

export default ListHeader;