import * as React from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import { Pagination } from '@mui/material';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#6c757d',
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const CustomTable = props => {

  const handleClick = id => {
    props.navigateToEdit(id);
  }

  const handleDeleteClick = id => {
    props.setId(id);
    props.setDeleteModalOpen(true);
  }

  return (
    <div style={{ marginTop: '10px'}}>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              {props.heading.map((item, index) => (
                <StyledTableCell align="center" key={index}>{item}</StyledTableCell>
              ))}
              {props.isDeleteBtnVisible && <StyledTableCell align="center">Action</StyledTableCell>}
            </TableRow>
          </TableHead>
          <TableBody>
            {props.rows.map((row, index) => (
              <StyledTableRow key={index} style={{ cursor: 'pointer' }}>
                <StyledTableCell align="center" key={`SI-${index}`} onClick={() => handleClick(row.id)}>{((props.pageData.current.pageNumber - 1) *10) + (index + 1)}</StyledTableCell>
                {Object.keys(row).map((key, i) => (
                  ((key !== "id" && key !== "role_id") && <StyledTableCell align="center" key={i} onClick={() => handleClick(row.id)}>{row[key]}</StyledTableCell>)
                ))}
                {props.isDeleteBtnVisible && <StyledTableCell align="center" key={`Delete-${index}`}><DeleteOutlinedIcon onClick={() => handleDeleteClick(row.id)} /></StyledTableCell>}
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default CustomTable;