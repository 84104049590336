import { useState, useEffect } from 'react';

import axios from 'axios';

import { baseURL, resObj } from '../../utils/constants';

import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import ErrorModal from '../../components/layout/ErrorModal';
import Loader from '../../components/layout/Loader';
import SuccessAlert from "../../components/layout/SuccessAlert";

import styles from "./Login.module.css";
import logo from "../../assets/images/logo.png";

const theme = createTheme();

const ForgotPasswordPage = () => {
  const [apiRes, setApiRes] = useState(resObj);
  const [email, setEmail] = useState('');
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);

  const submitForm = e => {
    e.preventDefault();
    setFormErrors(validate());
    setIsSubmit(true);
  };

  const validate = () => {
    const errors = {};
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (!email) {
      errors.email = 'Email is required!';
    } else if (!emailRegex.test(email)) {
      errors.email = 'This is not a valid email format!';
    }
    return errors;
  };

  useEffect(() => {
    if (Object.keys(formErrors).length === 0 && isSubmit) {
      forgotPassowrd();
    }
  }, [formErrors]);

  const forgotPassowrd = async () => {
    try {
      setApiRes({...apiRes, loading: true});
      const response = await axios.post(`${baseURL}/api/user/forgot/password`, {
        email: email,
      });

      if (response.status === 200) {
        setEmail('');
        setApiRes({
          ...apiRes,
          showAlert: true,
          successMsg: response.data,
          loading: false,
        });
      }
    } catch (err) {
      setApiRes({
        ...apiRes,
        axiosError: true,
        errMsg: JSON.stringify(err.response.data),
        errHeading: 'Forgot Password',
        loading: false,
      });
    }
  };

  return(
    <div className={styles.background}>
      <ThemeProvider theme={theme}>
        <Container component="main" maxWidth="xs">
          {apiRes.loading && <Loader />}
          {apiRes.showAlert && <SuccessAlert apiRes={apiRes} setApiRes={setApiRes} />}
          <CssBaseline />
          <Box
            sx={{
              marginTop: 8,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              backgroundColor: '#fcfafa',
              padding: '50px',
              borderRadius: '20px',
              width: '450px'
            }}
          >
            <img src={logo} className={styles.logo} alt='logo' />
            <Box component="form" onSubmit={submitForm} sx={{ mt: 1 }}>
              <TextField
                margin="normal"
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                helperText={formErrors.email}
                error={'email' in formErrors}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2}}
                className={styles.btnStyle}
              >
                Send Password Reset Link
              </Button>
            </Box>
          </Box>
        </Container>
      </ThemeProvider>
      <ErrorModal apiRes={apiRes} setApiRes={setApiRes} />
    </div>
  );
};

export default ForgotPasswordPage;