const style = {
    position: 'fixed',
    left: '0',
    right: '0',
    bottom: '0',
    top: '0',
    backgroundColor: 'rgba(0,0,0,0.6)',
  }
  
  const BackDrop = () => {
    return (
      <div style={style}></div>
    );
  };
  
  export default BackDrop;