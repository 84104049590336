import PropTypes from 'prop-types';

import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

import { Form } from "../../utils/useForm";

import styles from './RoleForm.module.css';

const RoleForm = ({
  values, handleInputChange, handleClick, selectedPermissions, submitForm,
  formErrors, isSubmitBtnVisible,
}) => {
  return(
    <div>
      <Form onSubmit={submitForm} style={{padding: '20px'}}>

        <Grid container spacing={1} >
          <Grid item xs={12} >
            <TextField
              varient="outlined"
              label="Name"
              name='roleName'
              value={values.roleName}
              onChange={handleInputChange('roleName')}
              helperText={formErrors.roleName}
              error={'roleName' in formErrors}
              style={{backgroundColor: 'white', width: '99%'}}
            />
          </Grid>
        </Grid>

        <Grid container spacing={1}>
          <Grid item xs={12}>
            <TextField
              varient="outlined"
              label="Description"
              name='description'
              value={values.description}
              onChange={handleInputChange('description')}
              helperText={formErrors.description}
              error={'description' in formErrors}
              multiline
              rows={4}
              style={{backgroundColor: 'white', width: '99%', marginTop: '10px' }}
            />
          </Grid>
        </Grid>
        
        {values.permissions.map((data, index) => (
          <Grid container key={index}>
            <Box key={index} className={styles.permissionsList}>
              <Typography 
                className={styles.pemissionheading}
                component="h6" 
                variant="h6" 
                color={'text.secondary'}
              >
                <strong>{data.module_name}</strong>
              </Typography>
              {data.module_permissions_list.map((permission) => (
                <Box className={styles.permissionModule} key={permission.permission_index}>
                  {permission.permissions_list.map((item, i) => (
                    <Box key={`inline-${item.name}`} className={styles.permission}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            name={item.permission_name}
                            value={item.permission_id}
                            id={`inline-${item.permission_id}-1`}
                            checked={selectedPermissions.includes(item.permission_id)}
                            onChange={handleClick}
                          />
                        }
                        label={<Typography style={{ color: '#6c757d' }}>{item.permission_name}</Typography>}
                      />
                    </Box>
                  ))}
                </Box>
              ))}
            </Box>
          </Grid>
        ))}

        {
          'permissions' in formErrors && 
          <span style={{
            color: '#d32f2f',
            fontSize: '12px',
            marginLeft: '10px',
          }}>
            {formErrors.permissions}
          </span>
        }
        
        <Grid container style={{ marginLeft: '10px', marginTop: '10px'}}>
          {isSubmitBtnVisible && <Button 
            variant='contained' 
            type='submit'
            color="success"
          >
            Submit
          </Button>}
        </Grid>

      </Form>
    </div>
  );
};

RoleForm.propTypes = {
  values: PropTypes.object.isRequired,
  handleInputChange: PropTypes.func.isRequired,
  handleClick: PropTypes.func.isRequired,
  selectedPermissions: PropTypes.array.isRequired,
  submitForm: PropTypes.func.isRequired,
  formErrors: PropTypes.object.isRequired,
  isSubmitBtnVisible: PropTypes.bool.isRequired,
};

export default RoleForm;