import React, { useState, useRef, useEffect } from "react";
import Filter from "./Filter";
import GroupedLineChart from "./GroupedLineChart";
import GroupedBarChart from "./GroupedBarChart";

const GroupedGraph = ({ rawData, alignment }) => {
  const [selectedXAxis, setSelectedXAxis] = useState("age"); // State for selected X-axis
  const [selectedGrouping, setSelectedYAxis] = useState("location"); // State for selected Y-axis

  const handleFilterChange = (selectedAxes) => {
    // Handle the selected X-axis and Y-axis values here
    setSelectedXAxis(selectedAxes.x);
    setSelectedYAxis(selectedAxes.y);
    // UpselectedXAxis the chart with the selected values if needed
  };
  const data = [];

  //Iterate through patient data
  rawData.forEach((patient) => {
    const patientInfo = {
      age: patient.age,
      full_name: patient.full_name,
      sex: patient.sex,
    };

    // Iterate through the patient's migraine records
    patient.migraine_list.forEach((migraine) => {
      const migraineRecord = {
        ...patientInfo,
        endDate: migraine.end_date,
        location: migraine.location,
        intensity: migraine.intensity,
        migraine_type: migraine.migraine_type,
        startDate: migraine.start_date,
      };

      data.push(migraineRecord);
    });
  });


  return (
    <div>
      

      {alignment == "line" ? (
        <>
        <Filter onFilterChange={handleFilterChange} disabled={false} marginLeft={'20%'}/>
        <GroupedLineChart
          data={data}
          selectedXAxis={selectedXAxis}
          selectedGrouping={selectedGrouping}
        />
        </>
        
      ) : (
        <>
        <Filter onFilterChange={handleFilterChange} disabled={true} marginLeft={'20%'} />
        <GroupedBarChart
          data={data}
          selectedXAxis={selectedXAxis}
          selectedGrouping={selectedGrouping}
        />
        </>
        
      )}
    </div>
  );
};

export default GroupedGraph;
