import { useContext } from 'react';
import { Link } from "react-router-dom";

import QueryStatsIcon from '@mui/icons-material/QueryStats';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import AccessibilityIcon from '@mui/icons-material/Accessibility';
import GroupsIcon from '@mui/icons-material/Groups';
import LogoutIcon from '@mui/icons-material/Logout';
import DashboardIcon from '@mui/icons-material/Dashboard';

import AuthContext from '../../context/AuthContext';
import PermissionContext from '../../context/PermissionContext';

import styles from './MainNavigation.module.css';
import logo from '../../assets/images/logo.png';

const MainNavigation = () => { 
  const { logoutUser } = useContext(AuthContext);
  const { permisionsList } = useContext(PermissionContext);

  return (
    <div className={styles.wrapper}>
      <div className={styles.sidebar}>
        <div>
          <img src={logo} className={styles.logo} alt='logo' />
        </div>
        <ul>
          {['Analytics-View Dashboard'].some(r => permisionsList.includes(r)) && <li><Link to="/" className={styles.link}><DashboardIcon className={styles.fas} />Dashboard</Link></li>}
          {['Analytics-Querying'].some(r => permisionsList.includes(r)) && <li><Link to="/querying" className={styles.link}><QueryStatsIcon className={styles.fas} />Querying</Link></li>}
          <li><Link to="/user-profile" className={styles.link}><AccountCircleIcon className={styles.fas} />Profile</Link></li>
          {['Admin-Role Create', 'Admin-Manage Role', 'Admin-View Role List'].some(r => permisionsList.includes(r)) && <li><Link to="/roles" className={styles.link}><AccessibilityIcon className={styles.fas} />Roles</Link></li>}
          {['Admin-User Create', 'Admin-Manage User', 'Admin-View User List'].some(r => permisionsList.includes(r)) && <li><Link to="/users" className={styles.link}><GroupsIcon className={styles.fas} />Users</Link></li>}
          <li onClick={logoutUser}><Link to="/login" className={styles.link}><LogoutIcon className={styles.fas} />Logout</Link></li>
        </ul>
      </div>
    </div>
  );
};

export default MainNavigation;