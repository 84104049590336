import loader from "../../assets/images/loader.gif";
import styles from "./Loader.module.css";

const Loader = () => {
  return(
    <div className={styles.loader}>
      <img src={loader} alt="Loader" />
    </div>
  );
};

export default Loader;