import React, { useEffect, useRef } from 'react';
import * as d3 from 'd3';

const QuestionnaireBarChart = ({ data, width = 380, height = 300 }) => {
  const chartRef = useRef(null);

  useEffect(() => {
    if (data && chartRef.current) {
      // Set up SVG dimensions
      const svg = d3.select(chartRef.current)
        .attr('width', width)
        .attr('height', height);

      // Clear previous content
      svg.selectAll('*').remove();

      // Define margins
      const margin = { top: 20, right: 20, bottom: 40, left: 40 };
      const chartWidth = width - margin.left - margin.right;
      const chartHeight = height - margin.top - margin.bottom;

      // Create color scale for unique colors
      const colorScale = d3.scaleOrdinal(d3.schemeCategory10)
        .domain(data.map(d => d.label));

      // Create scales
      const xScale = d3.scaleBand()
        .domain(data.map(d => d.type))
        .range([0, chartWidth])
        .padding(0.1);

      const yScale = d3.scaleLinear()
        .domain([0, d3.max(data, d => d.avg_answer_value)])
        .range([chartHeight, 0]);

      // Append group for chart content
      const chart = svg.append('g')
        .attr('transform', `translate(${margin.left},${margin.top})`);
      
      chart.append("g")
        .attr("class", "grid")
        .call(
          d3.axisLeft(yScale)
            .ticks(5)
            .tickSize(-chartWidth) // Extend ticks across the chart width
            .tickFormat("") // Remove tick labels
        )
        .call((g) => g.select(".domain").remove()) // Remove the axis line
        .call((g) =>
          g.selectAll(".tick:not(:first-of-type) line")
            .attr("stroke-opacity", 0.5)
            .attr("stroke-dasharray", "2,2")
        )
        .call((g) => g.selectAll(".tick text").remove()); // Ensure text labels are removed

      // Vertical grid lines
      chart.append("g")
        .attr("class", "grid")
        .call(
          d3.axisTop(xScale)
            .tickSize(chartHeight) // Extend ticks across the chart height
            .tickFormat("") // Remove tick labels
        )
        .attr("transform", `translate(0,${chartHeight})`)
        .call((g) => g.select(".domain").remove()) // Remove the axis line
        .call((g) => g.selectAll(".tick line")
          .attr("stroke-opacity", 0.5)
          .attr("stroke-dasharray", "2,2")
        )
        .call((g) => g.selectAll(".tick text").remove()); // Ensure text labels are removed

      // Create X-axis
      chart.append('g')
        .attr('transform', `translate(0, ${chartHeight})`)
        .call(d3.axisBottom(xScale));

      // Create Y-axis
      chart.append('g')
        .call(d3.axisLeft(yScale));

      // Draw bars
      chart.selectAll('.bar')
        .data(data)
        .enter()
        .append('rect')
        .attr('class', 'bar')
        .attr('x', d => xScale(d.type) + (xScale.bandwidth() * 0.2))
        .attr('y', d => yScale(d.avg_answer_value))
        .attr('width', xScale.bandwidth() * 0.6)
        .attr('height', d => chartHeight - yScale(d.avg_answer_value))
        .attr('fill', d => colorScale(d.type)); // Apply color based on label
    }
  }, [data, width, height]);

  return (
    <svg ref={chartRef}></svg>
  );
};

export default QuestionnaireBarChart;