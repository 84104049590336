import React, { useEffect, useRef } from 'react';
import * as d3 from 'd3';

const PieChart = ({ data }) => {
  const ref = useRef();

  useEffect(() => {
    if (!data || data.length === 0) return;

    // Function to guess label and value fields
    const guessLabelAndValueFields = (item) => {
      const keys = Object.keys(item);
      if (keys.length < 2) {
        console.error("Data format not supported for PieChart.");
        return { labelField: null, valueField: null };
      }
      // Assuming the first key is for labels and the second key is for values
      return { labelField: keys[0], valueField: keys[1] };
    };

    const { labelField, valueField } = guessLabelAndValueFields(data[0]);

    if (!labelField || !valueField) return;

    const svg = d3.select(ref.current);
    const width = 300;
    const height = 300;
    const radius = Math.min(width, height) / 2;

    const color = d3.scaleOrdinal()
      .domain(data.map(d => d[labelField]))
      .range(d3.quantize(t => d3.interpolateSpectral(t * 0.8 + 0.1), data.length).reverse());

    const pie = d3.pie().value(d => d[valueField]);
    const arc = d3.arc().innerRadius(0).outerRadius(radius);
    const labelArc = d3.arc().innerRadius(radius * 0.8).outerRadius(radius * 0.8);

    const arcs = pie(data);

    svg.attr("width", width).attr("height", height);
    const g = svg.append("g").attr("transform", `translate(${width / 2}, ${height / 2})`);

    g.selectAll("path")
      .data(arcs)
      .enter()
      .append("path")
      .attr("fill", d => color(d.data[labelField]))
      .attr("d", arc);

      g.selectAll("text")
      .data(arcs)
      .enter()
      .append("text")
      .attr("transform", function(d) {
        const [x, y] = labelArc.centroid(d);
        // const r = (labelArc.innerRadius()(d) + labelArc.outerRadius()(d)) / 2; // Average radius of the arc
        const scaleFactor = 0.8; // Adjust this factor to move text closer or farther
        return `translate(${x * scaleFactor}, ${y * scaleFactor})`;
      })
      .attr("dy", "0.10em")
      .attr("text-anchor", "middle")
      .text(d => `${d.data[labelField]}: ${d.data[valueField]}`)
      .style("fill", "black")
      .style("font-size", "18px");
    
  }, [data]);

  return (
    <>
     <svg ref={ref}></svg>
    </>
  )
 
};

export default PieChart;