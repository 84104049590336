import { Route, Routes } from 'react-router-dom';

import { AuthProvider } from './context/AuthContext';
import PrivateRoute from './utils/PrivateRoute';
import TwoFactorRoute from './utils/TwoFactorRoute';
import { PermissionProvider } from './context/PermissionContext';
import { QueryProvider } from './context/QueryContext';
import { NotificationProvider } from './context/NotificationContext';

// Account
import LoginPage from './pages/account/Login';
import UserProfilePage from './pages/account/UserProfile';
import ForgotPasswordPage from './pages/account/ForgotPassword';
import ResetPasswordPage from './pages/account/ResetPassword';
import EmailVerificationPage from './pages/account/EmailVerification';

// Roles
import RoleListPage from './pages/user_mngmnt/RoleList';
import RoleCreatePage from './pages/user_mngmnt/RoleCreate';
import RoleEditPage from './pages/user_mngmnt/RoleEdit';

// User
import UserListPage from './pages/user_mngmnt/UserList';

// Analytic
import DashboardPage from './pages/analytics/Dashboard';
import DashboardNewPage from './pages/analytics/DashboardNew';
import DataVisualisationPage from './pages/analytics/DataVisualisation';
import QueryPage from './pages/analytics/Query';
import QueryDetailPage from './pages/analytics/QueryDetail';

//Common
import PageNotFound from './pages/common/PageNotFound';
import NotificationPage from './pages/common/Notification';
import HealthCheck from './utils/HealthCheck';

const App = () => {
  return (
    <div>
      <Routes>
        <Route path='/login' element={<AuthProvider><LoginPage /></AuthProvider>} />

        <Route path='/emailverification' element={
          <AuthProvider>
            <TwoFactorRoute Component={EmailVerificationPage} />
          </AuthProvider>} 
        />

        <Route exact path='/' element={
          <AuthProvider>
            <PermissionProvider>
              <NotificationProvider>
                <PrivateRoute Component={DashboardNewPage} />
              </NotificationProvider>
            </PermissionProvider>
          </AuthProvider>}
        />

        <Route exact path='/dashboard' element={
          <AuthProvider>
            <PermissionProvider>
              <NotificationProvider>
                <PrivateRoute Component={DashboardPage} />
              </NotificationProvider>
            </PermissionProvider>
          </AuthProvider>}
        />

        <Route exact path='/data-visualisation' element={
          <AuthProvider>
            <PermissionProvider>
              <QueryProvider>
                <NotificationProvider>
                  <PrivateRoute Component={DataVisualisationPage} />
                </NotificationProvider>
              </QueryProvider>
            </PermissionProvider>
          </AuthProvider>}
        />

        <Route exact path='/querying' element={
          <AuthProvider>
            <PermissionProvider>
              <QueryProvider>
                <NotificationProvider>
                  <PrivateRoute Component={QueryPage} />
                </NotificationProvider>
              </QueryProvider>
            </PermissionProvider>
          </AuthProvider>}
        />

        <Route path='/query/user/:id' element={
          <AuthProvider>
            <PermissionProvider>
              <QueryProvider>
                <NotificationProvider>
                  <PrivateRoute Component={QueryDetailPage} />
                </NotificationProvider>
              </QueryProvider>
            </PermissionProvider>
          </AuthProvider>}
        />

        <Route exact path='/roles' element={
          <AuthProvider>
            <PermissionProvider>
              <NotificationProvider>
                <PrivateRoute Component={RoleListPage} />
              </NotificationProvider>
            </PermissionProvider>
          </AuthProvider>}
        />

        <Route exact path='/user-profile' element={
          <AuthProvider>
            <PermissionProvider>
              <NotificationProvider>
                <PrivateRoute Component={UserProfilePage} />
              </NotificationProvider>
            </PermissionProvider>
          </AuthProvider>}
        />

        <Route exact path='/users' element={
          <AuthProvider>
            <PermissionProvider>
              <NotificationProvider>
                <PrivateRoute Component={UserListPage} />
              </NotificationProvider>
            </PermissionProvider>
          </AuthProvider>}
        />

        <Route exact path='/role/create' element={
          <AuthProvider>
            <PermissionProvider>
              <NotificationProvider>
                <PrivateRoute Component={RoleCreatePage} />
              </NotificationProvider>
            </PermissionProvider>
          </AuthProvider>}
        />

        <Route path='/role/edit/:id' element={
          <AuthProvider>
            <PermissionProvider>
              <NotificationProvider>
                <PrivateRoute Component={RoleEditPage} />
              </NotificationProvider>
            </PermissionProvider>
          </AuthProvider>}
        />

        <Route path='/notifications' element={
          <AuthProvider>
            <PermissionProvider>
              <NotificationProvider>
                <PrivateRoute Component={NotificationPage} />
              </NotificationProvider>
            </PermissionProvider>
          </AuthProvider>}
        />

        <Route path='/forgot-password' element={<ForgotPasswordPage />}/>
        <Route path='/page-not-found' element={<PageNotFound />}/>
        <Route path='/reset-password/:code' element={<ResetPasswordPage />}/>
        <Route path='/health' element={<HealthCheck />}/>

        <Route path='*' element={<PageNotFound />}/>
      </Routes>
    </div>
  );
}

export default App;
