import { useEffect, useRef } from "react";
import PropTypes from 'prop-types';
import * as d3 from "d3";
import CreateLegend from "./CreateLegend";

const DountChart = ({ data }) => {
  const ref = useRef();
  const width = 170;

  const color = d3
    .scaleOrdinal()
    .domain(data.map((d) => d?.trigger))
    .range(
      d3
        .quantize((t) => d3.interpolateSpectral(t * 0.8 + 0.1), data.length)
        .reverse()
    );

  useEffect(() => {
    if (!data || data.length === 0) return;

    const svg = d3.select(ref.current);
    const height = Math.min(width, 500);
    const radius = Math.min(width, height) / 2;

    const arc = d3
      .arc()
      .innerRadius(radius * 0.5)
      .outerRadius(radius - 1);

    const pie = d3
      .pie()
      .padAngle(1 / radius)
      .sort(null)
      .value((d) => d.count);

    svg
      .attr("width", width)
      .attr("height", height)
      .attr("viewBox", [-width / 2, -height / 2, width, height])
      .attr(
        "style",
        "max-width: 100%; height: auto; height: auto; display: block; margin: 0 auto;"
      );

    svg.selectAll("*").remove();

    svg
      .append("g")
      .selectAll("path")
      .data(pie(data))
      .join("path")
      .attr("fill", (d) => color(d.data.trigger))
      .attr("d", arc)
      .append("title")
      .text((d) => `${d.data?.trigger}: ${d.data?.count.toLocaleString()}`);
  }, []);

  return (
    <div className="flex flex-col my-4">
      <div>
        <svg ref={ref}></svg>
      </div>
      <CreateLegend
        colorScale={color}
        data={data}
        displayText={"trigger"}
        classname={"flex flex-wrap w-5/6 mx-auto my-4"}
      />
    </div>
  );
};

DountChart.propTypes = {
  data: PropTypes.array.isRequired,
};

export default DountChart;
