import { useState, useEffect, useContext } from "react";

import MainNavigation from "../../components/layout/MainNavigation";
import TopBarWithBack from "../../components/layout/TopBarWithBack";
import RoleForm from "../../components/user_mngmnt/RoleForm";
import ErrorModal from "../../components/layout/ErrorModal";
import SuccessAlert from "../../components/layout/SuccessAlert";
import Loader from "../../components/layout/Loader";

import CssBaseline from '@mui/material/CssBaseline';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import { useForm } from "../../utils/useForm";
import useAxios from "../../utils/useAxios";
import PermissionContext from "../../context/PermissionContext";
import AutoLogout from "../../utils/useAutoLogout";
import { resObj } from "../../utils/constants";

const theme = createTheme();

const RoleEditPage = () => {
  const api = useAxios();

  const backURL = '/roles';
  const docUrIArr = window.location.href.split('/');
  const roleId = docUrIArr[docUrIArr.length - 1];

  const {permisionsList, checkUserPermission} = useContext(PermissionContext);

  useEffect(() => {
    if(permisionsList.length > 0) checkUserPermission(['Admin-Manage Role']);
  }, [permisionsList]);

  const initialValues = {
    roleName: '',
    description: '',
    permissions: [],
  };

  const {values, setValues, handleInputChange} = useForm(initialValues);
  const [apiRes, setApiRes] = useState(resObj);
  const [selectedPermissions, setSelectedPermissions] = useState([]);

  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);

  const handleSubmit = e => {
    e.preventDefault();
    setFormErrors(validate(values));
    setIsSubmit(true);
  };

  const validate = values => {
    const errors = {};
    if (!values.roleName) {
      errors.roleName = 'Name is required!';
    }
    if (!values.description) {
      errors.description = 'Description is required!';
    }
    if (selectedPermissions.length === 0) {
      errors.permissions = 'Permission is required!';
    }
    return errors;
  };

  useEffect(() => {
    if (Object.keys(formErrors).length === 0 && isSubmit) {
      roleEdit();
    }
  }, [formErrors]);
  
  const handleClick = e => {
    const permissionId = e.target.value;
    if (e.target.checked) {
      setSelectedPermissions(arr => [...arr, +permissionId]);
    } else {
      setSelectedPermissions(arr => arr.filter(val => val !== +permissionId));
    }
  };

  useEffect(() => {
    getRoleDetails();
  }, []);

  const getPemissions = async repsonseData => {
    try {
      setApiRes({...apiRes, loading: true});
      const response = await api.get('/api/permissions/get');

      setApiRes({...apiRes, loading: false});
      if (response.status === 200) {
        setValues({
          ...values,
          roleName: repsonseData.name,
          description: repsonseData.description,
          permissions: response.data,
        });
      }
    } catch (err){
      setApiRes({
        ...apiRes,
        axiosError: true,
        errMsg: JSON.stringify(err.response.data),
        errHeading: 'Permissions Get',
      });
    }
  };

  const getRoleDetails = async () => {
    try {
      setApiRes({...apiRes, loading: true});
      const response = await api.get('/api/role/details/get', {
        params: { role_id: roleId }
      });

      
      if (response.status === 200) {
        setApiRes({
          ...apiRes, 
          loading: false, 
          showAlert: true, 
          successMsg: 'Role details get successfully'
        });
        setSelectedPermissions(response.data.permissions_list);
        getPemissions(response.data);
      }
    } catch (err){
      setApiRes({
        ...apiRes,
        axiosError: true,
        errMsg: JSON.stringify(err.response.data),
        errHeading: 'Permissions Get',
        loading: false,
      });
    }
  };

  const roleEdit = async () => {
    try {
      setApiRes({...apiRes, loading: true});
      const response = await api.post('/api/role/edit', {
        role_id: roleId,
        role_name: values.roleName,
        description: values.description,
        permission_list: JSON.stringify(selectedPermissions),
      });

      if (response.status === 200) {
        setApiRes({
          ...apiRes,
          showAlert: true,
          successMsg: response.data,
          loading: false,
        });
      }
    } catch (err) {
      setApiRes({
        ...apiRes,
        axiosError: true,
        errMsg: JSON.stringify(err.response.data),
        errHeading: 'Role Create',
        loading: false,
      });
    }
  };

  return(
    <AutoLogout>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <MainNavigation />
        {apiRes.loading && <Loader />}
        <div className="mainContainer">
          {apiRes.showAlert && <SuccessAlert apiRes={apiRes} setApiRes={setApiRes} />}
          <TopBarWithBack title={'Role Edit'} backURL={backURL} />
          <RoleForm
            values={values}
            handleInputChange={handleInputChange}
            handleClick={handleClick}
            selectedPermissions={selectedPermissions}
            submitForm={handleSubmit}
            formErrors={formErrors}
            isSubmitBtnVisible={permisionsList.includes('Admin-Manage Role')}
           />
           <ErrorModal apiRes={apiRes} setApiRes={setApiRes} />
        </div>
      </ThemeProvider>
    </AutoLogout>
  );
};

export default RoleEditPage;