import { useState, useContext, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";

import PermissionContext from "../../context/PermissionContext";
import QueryContext from "../../context/QueryContext";
import useAxios from "../../utils/useAxios";
import AutoLogout from "../../utils/useAutoLogout";
import { resObj, paginationVal } from "../../utils/constants";

import Pagination from "@mui/material/Pagination";

import MainNavigation from "../../components/layout/MainNavigation";
import QueryForm from "../../components/analytics/QueryForm";
import FilterHeader from "../../components/layout/FilterHeader";
import CustomTable from "../../components/layout/CustomTable";
import NoData from "../../components/layout/NoData";
import Loader from "../../components/layout/Loader";
import ErrorModal from "../../components/layout/ErrorModal";
import Graph from "../../components/analytics/Graph";

const QueryPage = () => {
  const api = useAxios();
  const navigate = useNavigate();

  const { permisionsList, checkUserPermission } = useContext(PermissionContext);

  useEffect(() => {
    if(permisionsList.length > 0) checkUserPermission(['Analytics-Querying']);
  }, [permisionsList]);

  const { values, setValues, handleInputChange, initialValues } = useContext(QueryContext);
  const filterValues = useContext(QueryContext).values;

  const [apiRes, setApiRes] = useState(resObj);
  const [filterIsOpen, setFilterIsOpen] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [GraphIcon, setGraphIcon] = useState(false)
  const [filterDataList, setFilterDataList] = useState([]);
  const [graphData, setGraphData] = useState([]);

  const tableHeading = [
    "SI No", "Age", "Gender", "No of Migraines", "No of Therapies", 
    "No of Questionnaire", "No of Measurements"
  ];

  const handleFilter = () => {
    getQueryFilters();
  };

  const handleModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const getIndex = () => {
    const filterIndex = {
      ruleIndex: 0,
      groupIndex: 0,
      groupRuleIndex: 0,
    };

    if (values.dynamicList.length > 0) {
      const rulesList = values.dynamicList.filter(
        obj => obj.type === 'Rule'
      ).sort(
        (obj1, obj2) => obj2.index - obj1.index
      );

      if (rulesList.length > 0) {
        filterIndex.ruleIndex = rulesList[0].index + 1;
      }

      const groupList = values.dynamicList.filter(obj => obj.type === 'Group').sort(
        (obj1, obj2) => obj2.groupIndex - obj1.groupIndex
      );

      let maxRuleIndex = 0;
      for (const groupItem of groupList) {
        for (const item of groupItem.rules) {
          if (item.ruleIndex > maxRuleIndex) {
            maxRuleIndex = item.ruleIndex;
          }
        }
      }

      if (groupList.length > 0) {
        filterIndex.groupIndex = groupList[0].groupIndex + 1;
        filterIndex.groupRuleIndex = maxRuleIndex + 1;
      }
    }

    return filterIndex;
  };

  const pageData = useRef(paginationVal);
  const indexRef = useRef(getIndex());
  const [queryResList, setQueryResList] = useState([]);

  useEffect(() => {
    getQueryResult();
  }, []);

  const getQueryResult = async () => {
    try {
      setApiRes({ ...apiRes, loading: true });
      const response = await api.get("/api/analytics/query/result/get", {
        params: {
          search_term: pageData.current.searchTerm,
          page_number: +pageData.current.pageNumber,
          no_of_migraines: +values?.noOfMigraines,
          migraine_operator: values?.migraineOperator,
          start_date: values?.startDate,
          end_date: values?.endDate,
          age: values?.age,
          age_operator: values?.ageOperator,
          sex: values?.sex,
          main_operator: values?.mainOperator,
          dynamic_list: JSON.stringify(values?.dynamicList),
        },
      });

      if(response.data.data.length > 0) {
        setGraphIcon(true);
      }
  
      if (response.status === 200) {
        setQueryResList(response.data.data);
        pageData.current.pageCount = response.data.page_count;
        setApiRes({ ...apiRes, loading: false });
        // getGraphData();
      }
    } catch (err) {
      setApiRes({
        ...apiRes,
        axiosError: true,
        errMsg: JSON.stringify(err.response.data),
        errHeading: "Query Results Get",
        loading: false,
      });
    }
  };

  const getGraphData = async () => {
    try {
      setApiRes({ ...apiRes, loading: true });

      const response = await api.get("/api/analytics/graph/data/get", {
        params: {
          no_of_migraines: +filterValues?.noOfMigraines,
          migraine_operator: filterValues?.migraineOperator,
          start_date: filterValues?.startDate,
          end_date: filterValues?.endDate,
          age: filterValues?.age,
          age_operator: filterValues?.ageOperator,
          sex: filterValues?.sex,
          intensity: filterValues?.intensity,
          main_operator: filterValues?.mainOperator,
          dynamic_list: JSON.stringify(filterValues?.dynamicList),
        },
      });

      if (response.status === 200) {
        setGraphData(response.data);
        handleModal();
        setApiRes({
          ...apiRes,
          loading: false,
          showAlert: true,
          successMsg: "Graph data get successfully",
        });
      }
    } catch (err) {
      setApiRes({
        ...apiRes,
        axiosError: true,
        errMsg: JSON.stringify(err.response.data),
        errHeading: "Graph Data Get",
        loading: false,
      });
    }
  };

  const getQueryFilters = async () => {
    try {
      setApiRes({ ...apiRes, loading: true });
      const response = await api.get("/api/analytics/query/filters/get");
      if (response.status === 200) {
        setFilterDataList(response.data);
        setApiRes({ ...apiRes, loading: false });
        setFilterIsOpen(true);
      }
    } catch (err) {
      setApiRes({
        ...apiRes,
        axiosError: true,
        errMsg: JSON.stringify(err.response.data),
        errHeading: "Filters Get",
        loading: false,
      });
    }
  };

  const navigateToEdit = (userId) => {
    navigate(`/query/user/${userId}`);
  };

  const handlePaginationClick = (e, pageNumber) => {
    pageData.current.pageNumber = pageNumber;
    getQueryResult();
  };

  const addRule = () => {
    const newfield = {
      name: "",
      field_name: "",
      value: "",
      condition: "",
      table: "",
      index: indexRef.current.ruleIndex,
      type: "Rule",
    };

    setValues({
      ...values,
      dynamicList: [...values.dynamicList, newfield],
    });

    indexRef.current.ruleIndex = indexRef.current.ruleIndex + 1;
  };

  const hanldeDeleteRule = (index) => {
    setValues({
      ...values,
      dynamicList: values.dynamicList.filter((item) => item?.index !== index),
    });
  };

  const handleToggleGroup = (index) => {
    let data = [...values.dynamicList];
    data = data.map((obj) =>
      obj.groupIndex === index ? { ...obj, checked: !obj.checked } : obj
    );
    setValues({
      ...values,
      dynamicList: data,
    });
  };

  const handleChangeRuleDrpDwn = (index, e) => {
    const targetVal = e.target.value;
    const selected = targetVal.split('-');

    const defaultVal = filterDataList.filter(obj => obj.name === targetVal)[0]?.field_type === 'DateTimeField' ? null : '';
    
    let data = [...values.dynamicList];
    data = data.map((obj) =>
      obj.type === "Rule" && obj.index === index
        ? { ...obj, name: targetVal, field_name: selected[0], table: selected[1], value: defaultVal }
        : obj
    );
    setValues({
      ...values,
      dynamicList: data,
    });
  };

  const handleChangeValue = (index, fieldType, e) => {
    const value = fieldType === 'DateTimeField' ? e : e.target.value;
    let data = [...values.dynamicList];
    data = data.map((obj) =>
      obj.type === "Rule" && obj.index === index
        ? { ...obj, value: value }
        : obj
    );
    setValues({
      ...values,
      dynamicList: data,
    });
  };

  const handleChangeCondition = (index, e) => {
    let data = [...values.dynamicList];
    data = data.map((obj) =>
      obj.type === "Rule" && obj.index === index
        ? { ...obj, condition: e.target.value }
        : obj
    );
    setValues({
      ...values,
      dynamicList: data,
    });
  };

  const handleMainConditionChange = (e) => {
    setValues({
      ...values,
      mainOperator: e.target.value,
    });
  };

  const addGroup = () => {
    const newfield = {
      operator: "AND",
      groupIndex: indexRef.current.groupIndex,
      type: "Group",
      checked: true,
      rules: [],
    };

    setValues({
      ...values,
      dynamicList: [...values.dynamicList, newfield],
    });
    indexRef.current.groupIndex = indexRef.current.groupIndex + 1;
  };

  const addGroupRule = (index) => {
    const newfield = {
      name: "",
      field_name: "",
      value: "",
      condition: "",
      table: "",
      ruleIndex: indexRef.current.groupRuleIndex,
      type: "Rule",
    };

    let data = [...values.dynamicList];
    data = data.map((obj) =>
      obj.type === "Group" && obj.groupIndex === index
        ? { ...obj, rules: [...obj.rules, newfield] }
        : obj
    );
    setValues({
      ...values,
      dynamicList: data,
    });

    indexRef.current.groupRuleIndex = indexRef.current.groupRuleIndex + 1;
  };

  const handleChangeGroupRuleDrpDwn = (groupIndex, ruleIndex, e) => {
    const targetVal = e.target.value;
    const selected = targetVal.split('-');

    const defaultVal = filterDataList.filter(obj => obj.name === targetVal)[0]?.field_type === 'DateTimeField' ? null : '';

    let data = [...values.dynamicList];
    data = data.map((obj) =>
      obj.type === "Group" && obj.groupIndex === groupIndex
        ? {
            ...obj,
            rules: obj.rules.map((rule) =>
              rule.ruleIndex === ruleIndex
                ? { ...rule, name: targetVal, field_name: selected[0], table: selected[1], value: defaultVal }
                : rule
            ),
          }
        : obj
    );
    setValues({
      ...values,
      dynamicList: data,
    });
  };

  const handleChangeGroupValue = (groupIndex, ruleIndex, fieldType, e) => {
    const value = fieldType === 'DateTimeField' ? e : e.target.value;
    let data = [...values.dynamicList];
    data = data.map((obj) =>
      obj.type === "Group" && obj.groupIndex === groupIndex
        ? {
            ...obj,
            rules: obj.rules.map((rule) =>
              rule.ruleIndex === ruleIndex
                ? { ...rule, value: value }
                : rule
            ),
          }
        : obj
    );
    setValues({
      ...values,
      dynamicList: data,
    });
  };

  const handleGroupConditionChange = (groupIndex, ruleIndex, e) => {
    let data = [...values.dynamicList];
    data = data.map((obj) =>
      obj.type === "Group" && obj.groupIndex === groupIndex
        ? {
            ...obj,
            rules: obj.rules.map((rule) =>
              rule.ruleIndex === ruleIndex
                ? { ...rule, condition: e.target.value }
                : rule
            ),
          }
        : obj
    );
    setValues({
      ...values,
      dynamicList: data,
    });
  };

  const hanldeDeleteGroup = (index) => {
    setValues({
      ...values,
      dynamicList: values.dynamicList.filter(
        (item) => item?.groupIndex !== index
      ),
    });
  };

  const hanldeDeleteGroupRule = (groupIndex, ruleIndex) => {
    let data = [...values.dynamicList];
    data = data.map((obj) =>
      obj.type === "Group" && obj.groupIndex === groupIndex
        ? {
            ...obj,
            rules: obj.rules.filter((rule) => rule.ruleIndex !== ruleIndex),
          }
        : obj
    );
    setValues({
      ...values,
      dynamicList: data,
    });
  };

  const clearFilterForm = () => {
    setValues(initialValues);
  };

  const handleGroupOperatorChange = (groupIndex, e) => {
    let data = [...values.dynamicList];
    data = data.map((obj) =>
      obj.type === "Group" && obj.groupIndex === groupIndex
        ? { ...obj, operator: e.target.value }
        : obj
    );

    setValues({
      ...values,
      dynamicList: data,
    });
  };

  return (
    <AutoLogout>
      <MainNavigation />
      {apiRes.loading && <Loader />}
      <div className="mainContainer">
        <FilterHeader
          title={"Querying"}
          btnName={"Filters"}
          handleFilter={handleFilter}
          search={getQueryResult}
          pageData={pageData}
          GraphIcon={GraphIcon}
          isGraphIconVisible={permisionsList.includes('Analytics-Querying')}
          getGraphData={getGraphData}
        />

        <Graph data={graphData} opengraph={modalIsOpen} close={closeModal}  />

        <QueryForm
          values={values}
          setValues={setValues}
          handleInputChange={handleInputChange}
          pageData={pageData}
          open={filterIsOpen}
          setOpen={setFilterIsOpen}
          search={getQueryResult}
          filterDataList={filterDataList}
          addRule={addRule}
          hanldeDeleteRule={hanldeDeleteRule}
          handleToggleGroup={handleToggleGroup}
          handleChangeRuleDrpDwn={handleChangeRuleDrpDwn}
          handleChangeValue={handleChangeValue}
          handleMainConditionChange={handleMainConditionChange}
          addGroup={addGroup}
          addGroupRule={addGroupRule}
          handleChangeGroupRuleDrpDwn={handleChangeGroupRuleDrpDwn}
          handleChangeGroupValue={handleChangeGroupValue}
          handleGroupConditionChange={handleGroupConditionChange}
          hanldeDeleteGroup={hanldeDeleteGroup}
          hanldeDeleteGroupRule={hanldeDeleteGroupRule}
          clearFilterForm={clearFilterForm}
          handleChangeCondition={handleChangeCondition}
          handleGroupOperatorChange={handleGroupOperatorChange}
        />

        <CustomTable
          heading={tableHeading}
          rows={queryResList}
          navigateToEdit={navigateToEdit}
          pageData={pageData}
        />

        {queryResList.length === 0 && <NoData />}

        {queryResList.length !== 0 && pageData.current.pageCount !== 1 && (
          <Pagination
            style={{ marginTop: "10px", float: "right" }}
            count={pageData.current.pageCount}
            onChange={handlePaginationClick}
          />
        )}

        <ErrorModal apiRes={apiRes} setApiRes={setApiRes} />
      </div>
    </AutoLogout>
  );
};

export default QueryPage;