import { useContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import axios from 'axios';
import jwt_decode from 'jwt-decode';

import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import AuthContext from '../../context/AuthContext';
import AutoLogout from '../../utils/useAutoLogout';
import { resObj, baseURL } from '../../utils/constants';

import ErrorModal from '../../components/layout/ErrorModal';
import Loader from '../../components/layout/Loader';
import SuccessAlert from "../../components/layout/SuccessAlert";

import styles from "./Login.module.css";
import logo from "../../assets/images/logo.png";

const theme = createTheme();

const EmailVerificationPage = () => {
  const navigate = useNavigate();
  
  const { authTokens, setUser } = useContext(AuthContext);

  const [apiRes, setApiRes] = useState(resObj);
  const [token, setToken] = useState('');
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);

  const handleSubmit = e => {
    e.preventDefault();
    setFormErrors(validate());
    setIsSubmit(true);
  };

  const validate = () => {
    const errors = {};
    const tokenLength = 15;
    
    if (!token) {
      errors.token = 'Verification code is required!';
    } else if (token.length !== tokenLength) {
      errors.token = 'Verification code must be 15 characters!';
    }

    return errors;
  };

  useEffect(() => {
    if (Object.keys(formErrors).length === 0 && isSubmit) {
      submitToken();
    }
  }, [formErrors]);
  
  const submitToken = async () => {

    try {
      setApiRes({...apiRes, loading: true});
      const jwt_data = jwt_decode(authTokens.access);
      
      const response = await axios.post(`${baseURL}/api/user/otp/verification`, {
        token: token,
        user_id: jwt_data?.user_id,
      });

      if (response.status === 200) {
        setUser(jwt_data);
        localStorage.setItem('authTokens', JSON.stringify(authTokens));
        navigate('/');
      }
    } catch (err) {
      if (err.response.data[0] === 'Invalid Token') {
        setFormErrors({ token: 'Invalid verification code!' });
        setApiRes({ ...apiRes, loading: false });
      } else {
        setApiRes({
          ...apiRes,
          axiosError: true,
          errMsg: JSON.stringify(err.response.data),
          errHeading: 'Validate OTP',
          loading: false,
        });
      }
    }
  };

  const resendOTPCode = async () => {
    try {
      setApiRes({...apiRes, loading: true});
      const jwt_data = jwt_decode(authTokens.access);
      
      const response = await axios.post(`${baseURL}/api/user/resend/otp/code`, {
        user_id: jwt_data?.user_id,
      });

      if (response.status === 200) {
        setApiRes({
          ...apiRes,
          showAlert: true,
          successMsg: response.data,
          loading: false,
        });
      }
    } catch (err) {
      setApiRes({
        ...apiRes,
        axiosError: true,
        errMsg: JSON.stringify(err.response.data),
        errHeading: 'Validate OTP',
        loading: false,
      });
    }
  };

  return(
    <AutoLogout>
      <div className={styles.background}>
        <ThemeProvider theme={theme}>
          <Container component="main" maxWidth="xs">
            {apiRes.loading && <Loader />}
            {apiRes.showAlert && <SuccessAlert apiRes={apiRes} setApiRes={setApiRes} />}
            <CssBaseline />
            <Box
              sx={{
                marginTop: 8,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                backgroundColor: '#fcfafa',
                padding: '50px',
                borderRadius: '20px',
                width: '450px'
              }}
            >
              <img src={logo} className={styles.logo} alt='logo' />
              <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
                <Typography 
                  color={'text.secondary'}
                >
                  <i>Please enter the verification code that was sent to your email. The code is valid only 10 mins.</i>
                </Typography>
                <TextField
                  margin="normal"
                  fullWidth
                  id="verificationCode"
                  label="Verification Code"
                  name="verificationCode"
                  type='password'
                  autoFocus
                  onChange={(e) => setToken(e.target.value)}
                  helperText={formErrors.token}
                  error={'token' in formErrors}
                />
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 1, mb: 3}}
                  className={styles.btnStyle}
                >
                  Verify
                </Button>

                <Typography 
                  color={'text.secondary'}
                >
                  <strong>Didn't recieve an otp?</strong>
                </Typography>
                
                <Button
                  fullWidth
                  variant="text"
                  sx={{ mt: 1, mb: 0}}
                  onClick={resendOTPCode}
                >
                  Resend Code
                </Button>
              </Box>
            </Box>
          </Container>
        </ThemeProvider>
        <ErrorModal apiRes={apiRes} setApiRes={setApiRes} />
      </div>
    </AutoLogout>
  );
};

export default EmailVerificationPage;