import PropTypes from 'prop-types';

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";

import MeasurementItem from './MeasurementItem';

const MeasuremetsHistory = ({ 
  measurementList, showAll, toggleShowAll 
}) => {

  return(
    <Box>
      <Grid container spacing={3} style={{ margin: '0px 5px 0px 5px' }}>
        <Grid item xs={4}>
          <Typography 
            component="h5" 
            variant="h5" 
            color={'text.secondary'}
          >
            <strong>Measurement - {measurementList.length}</strong>
          </Typography>
        </Grid>
      </Grid>

      <Grid container spacing={3} style={{ margin: '-15px 0px 0px 0px' }}>
        <Grid item xs={4}>
          <FormControlLabel
            control={
              <Checkbox
                name='showall'
                value={showAll.current.measurement}
                id='showall'
                checked={showAll.current.measurement}
                onChange={toggleShowAll}
              />
            }
            label={<i><Typography style={{ color: '#6c757d' }}>Show All</Typography></i>}
          />
        </Grid>
      </Grid>

      <Grid container spacing={3} style={{ marginTop: '-20px', marginLeft: '25px'}}>
        {measurementList.map((data, index) => 
          <MeasurementItem key={index} data={data} />
        )}
      </Grid>
    </Box>
  );
};

MeasuremetsHistory.propTypes = {
  measurementList: PropTypes.array.isRequired,
  showAll: PropTypes.object.isRequired,
  toggleShowAll: PropTypes.func.isRequired,
};

export default MeasuremetsHistory;