import { useState, useEffect, useContext } from "react";

import useAxios from "../../utils/useAxios";
import PermissionContext from "../../context/PermissionContext";
import AutoLogout from "../../utils/useAutoLogout";

import MainNavigation from "../../components/layout/MainNavigation";
import TopBar from "../../components/layout/TopBar";
import NotificationCard from "../../components/common/NotificationCard";
import Loader from "../../components/layout/Loader";
import SuccessAlert from "../../components/layout/SuccessAlert";
import NoData from "../../components/layout/NoData";

const NotificationPage = () => {
  const api = useAxios();
  const {apiRes, setApiRes} = useContext(PermissionContext);

  const [notificationsList, setNotificationsList] = useState([]);

  useEffect(() => {
    getNotifications();
  }, [])

  const getNotifications = async () => {
    try {
      setApiRes({...apiRes, loading: true});
      const response = await api.get('/api/user/notifications/list/get');
      
      if (response.status === 200) {
        setNotificationsList(response.data);
        setApiRes({
          ...apiRes, 
          loading: false, 
          showAlert: true,
          successMsg: 'Notifications get successfully'
        });
      }
    } catch (err){
      setApiRes({
        ...apiRes,
        axiosError: true,
        errMsg: JSON.stringify(err.response.data),
        errHeading: 'Notifications Get',
        loading: false,
      });
    }
  };

  return(
    <AutoLogout>
      <MainNavigation />
      {apiRes.loading && <Loader />}
      <div className="mainContainer">
        {apiRes.showAlert && <SuccessAlert apiRes={apiRes} setApiRes={setApiRes} />}
        <TopBar title={'Notifications'} />
        <div>
          {
            notificationsList.map((data, index) => (
              <NotificationCard key={index} data={data} />
            ))
          }
          {notificationsList.length === 0 && <NoData />}
        </div>
      </div>
    </AutoLogout>
  );
};

export default NotificationPage;