import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import useAxios from '../../utils/useAxios';

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Badge from '@mui/material/Badge';
import NotificationsIcon from '@mui/icons-material/Notifications';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import styles from './ListHeader.module.css';

const TopBarWithBack = props => {
  const api = useAxios();
  const navigate = useNavigate();

  const [count, setCount] = useState(0);

  const handleClick = () => {
    navigate('/notifications');
  };

  const handleBackClick = () => {
    navigate(props.backURL)
  };

  useEffect(() => {
    getNotifications();
  }, [])

  const getNotifications = async () => {
    try {
      const response = await api.get('/api/user/notifications/count/get');
      
      if (response.status === 200) {
        setCount(response.data);
      }
    } catch (err){
      console.log(err);
    }
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static" style={{ background: "#f8f8f8", color: "black", boxShadow: "0px 0px 0px 0px" }}>
        <Toolbar>
          <ArrowBackIcon 
            fontSize='large' 
            color='error' 
            className={styles.backBtn}
            onClick={handleBackClick}
          />
          <Typography component="h4" variant="h4" color={'text.secondary'}><strong>{props.title}</strong></Typography>
          <Box sx={{ flexGrow: 1 }} />
          <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
            <IconButton
              size="large"
              aria-label="show 17 new notifications"
              color="inherit"
              onClick={handleClick}
            >
              <Badge badgeContent={count} color="error">
                <NotificationsIcon color="action" />
              </Badge>
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>
    </Box>
  );
};

export default TopBarWithBack;