import { createContext, useState, useEffect } from 'react';
import  { useNavigate } from 'react-router-dom';

import useAxios from '../utils/useAxios';
import { resObj } from '../utils/constants';

const PermissionContext = createContext();

export default PermissionContext;


export const PermissionProvider = ({ children }) => {
  const api = useAxios();
  const navigate = useNavigate();

  useEffect(() => {
    getUserPermissions();
  }, [])

  const [apiRes, setApiRes] = useState(resObj);
  const [permisionsList, setPermisionsList] = useState([]);

  const getUserPermissions = async () => {
    try {
      setApiRes({...apiRes, loading: true});
      const response = await api.get('/api/user/permissions/get');

      setApiRes({...apiRes, loading: false});
      if (response.status === 200) {
        setPermisionsList(response.data);
      }
    } catch (err){
      setApiRes({
        ...apiRes,
        axiosError: true,
        errMsg: JSON.stringify(err.response.data),
        errHeading: 'Permissions Get',
      });
    }
  };

  const checkUserPermission = pList => {
    if (!pList.some(r => permisionsList.includes(r))) {
      navigate('/page-not-found');
    }
  };

  const contextData = {
    permisionsList: permisionsList,
    checkUserPermission: checkUserPermission,
  };

  return (
    <PermissionContext.Provider value={contextData}>
      {children}
    </PermissionContext.Provider>
  );
};
