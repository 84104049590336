import PropTypes from 'prop-types';

import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from "@mui/material/Typography";

import FemaleIcon from '@mui/icons-material/Female';
import MaleIcon from '@mui/icons-material/Male';

import { createTheme, ThemeProvider, styled } from '@mui/material/styles';

const theme = createTheme({ palette: { mode: 'light' } });

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  textAlign: 'center',
  color: theme.palette.text.secondary,
  height: 90,
  lineHeight: '60px',
}));

const valStyle = {
  fontSize: '40px',
  fontWeight: '600',
};

const FitBitChart = ({ data }) => {  
  return (
    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 1 }}>
      <Grid 
        item 
        xs={6} 
        sx={{ 
          textAlign: 'right',
          borderRight: '1px solid grey',
          height: 320,
        }}
      >
        <Box sx={{ display: 'flex', justifyContent: 'center'}}>
          <Box>
            <ThemeProvider theme={theme}>
              <Box
                sx={{
                  p: 2,
                  borderRadius: 2,
                  bgcolor: 'background.default',
                  display: 'grid',
                  gridTemplateColumns: { md: '1fr 1fr' },
                  gap: 2,
                }}
              >
                <Item elevation={0}>
                  <Typography 
                    sx={{ fontSize: 14 }} 
                    color="text.secondary"
                  >
                    Average Steps
                  </Typography>
                  <Typography variant="h5" component="div" style={valStyle}>
                    {data?.avg_female_goals?.steps}
                  </Typography>
                </Item>

                <Item elevation={0}>
                  <Typography 
                    sx={{ fontSize: 14 }} 
                    color="text.secondary"
                  >
                    Average Distance
                  </Typography>
                  <Typography variant="h5" component="div" style={valStyle}>
                    {data?.avg_female_goals?.distance}
                  </Typography>
                </Item>

                <Item elevation={0}>
                  <Typography 
                    sx={{ fontSize: 14 }} 
                    color="text.secondary"
                  >
                    Average Calories burn
                  </Typography>
                  <Typography variant="h5" component="div" style={valStyle}>
                    {data?.avg_female_goals?.caloriesOut}
                  </Typography>
                </Item>

                <Item elevation={0}>
                  <Typography 
                    sx={{ fontSize: 14 }} 
                    color="text.secondary"
                  >
                    Average Floors
                  </Typography>
                  <Typography variant="h5" component="div" style={valStyle}>
                    {data?.avg_female_goals?.floors}
                  </Typography>
                </Item>

                <Item elevation={0}>
                  <Typography 
                    sx={{ fontSize: 14 }} 
                    color="text.secondary"
                  >
                    Average Active Minutes
                  </Typography>
                  <Typography variant="h5" component="div" style={valStyle}>
                    {data?.avg_female_goals?.activeMinutes}
                  </Typography>
                </Item>
              </Box>
            </ThemeProvider>
          </Box>

          <Box>
            <FemaleIcon  
              fontSize='large' 
              sx={{ 
                height: 150, 
                width: 150, 
                color: '#ef6292' 
              }} 
            />
          </Box>
        </Box>
        
      </Grid>

      <Grid 
        item 
        xs={6} 
        sx={{ 
          textAlign: 'left',
          borderLeft: '1px solid grey',
          height: 320,
        }}>
          <Box 
            sx={{
              display: 'flex',
              justifyContent: 'center'
            }}
          >
            <Box>
              <MaleIcon 
                fontSize='large' 
                sx={{ height: 150, width: 150 }} 
                color= 'primary'
              />
            </Box>

            <Box>
              <ThemeProvider theme={theme}>
                <Box
                  sx={{
                    p: 2,
                    borderRadius: 2,
                    bgcolor: 'background.default',
                    display: 'grid',
                    gridTemplateColumns: { md: '1fr 1fr' },
                    gap: 2,
                  }}
                >
                  <Item elevation={0}>
                    <Typography 
                      sx={{ fontSize: 14 }} 
                      color="text.secondary"
                    >
                      Average Steps
                    </Typography>
                    <Typography variant="h5" component="div" style={valStyle}>
                      {data?.avg_male_goals?.steps}
                    </Typography>
                  </Item>

                  <Item elevation={0}>
                    <Typography 
                      sx={{ fontSize: 14 }} 
                      color="text.secondary"
                    >
                      Average Distance
                    </Typography>
                    <Typography variant="h5" component="div" style={valStyle}>
                      {data?.avg_male_goals?.distance}
                    </Typography>
                  </Item>

                  <Item elevation={0}>
                    <Typography 
                      sx={{ fontSize: 14 }} 
                      color="text.secondary"
                    >
                      Average Calories burn
                    </Typography>
                    <Typography variant="h5" component="div" style={valStyle}>
                      {data?.avg_male_goals?.caloriesOut}
                    </Typography>
                  </Item>

                  <Item elevation={0}>
                    <Typography 
                      sx={{ fontSize: 14 }} 
                      color="text.secondary"
                    >
                      Average Floors
                    </Typography>
                    <Typography variant="h5" component="div" style={valStyle}>
                      {data?.avg_male_goals?.floors}
                    </Typography>
                  </Item>

                  <Item elevation={0}>
                    <Typography 
                      sx={{ fontSize: 14 }} 
                      color="text.secondary"
                    >
                      Average Active Minutes
                    </Typography>
                    <Typography variant="h5" component="div" style={valStyle}>
                      {data?.avg_male_goals?.activeMinutes}
                    </Typography>
                  </Item>
                </Box>
              </ThemeProvider>
            </Box>
          </Box>
      </Grid>
    </Grid>
  );
};

FitBitChart.propTypes = {
  data: PropTypes.object.isRequired,
};

export default FitBitChart;