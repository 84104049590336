import React, { useState, useEffect, useContext } from "react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
// import SingleGraph from "./SingleGraph";
import GroupedGraph from "./GroupedGraph";
import QueryForm from "./Modal";
import Loader from "../layout/Loader";

export default function Graph({ data, opengraph, close }) {
  return (
    <div>
      <QueryForm opens={opengraph} close={close} data={data} /> 
    </div>
  );
}
