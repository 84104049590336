import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';

const SearchBar = props => {
  const handleSearch = () => {
    props.pageData.current.pageNumber = 1;
    props.search();
  };

  return(
    <Paper
      component="form"
      style={props.style}
      sx={{ p: '2px 4px', display: 'flex', alignItems: 'center'}}
    >
      <InputBase
        sx={{ ml: 1, flex: 1 }}
        placeholder="Search"
        inputProps={{ 'aria-label': 'search' }}
        onChange={e => props.pageData.current.searchTerm = e.target.value}
      />
      <IconButton type="button" sx={{ p: '6px' }} aria-label="search" onClick={handleSearch}>
        <SearchIcon />
      </IconButton>
    </Paper>
  );
};

export default SearchBar;