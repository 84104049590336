import { useState, useEffect, useContext } from "react";

import useAxios from "../../utils/useAxios";
import { resObj } from "../../utils/constants";
import AutoLogout from "../../utils/useAutoLogout";
import PermissionContext from "../../context/PermissionContext";

import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Typography from "@mui/material/Typography";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

import MainNavigation from "../../components/layout/MainNavigation";
import TopBar from "../../components/layout/TopBar";
import ErrorModal from "../../components/layout/ErrorModal";
import PieChart from "../../components/analytics/PieChart";
import BasicCard from "../../components/layout/BasicCard";
import Loader from "../../components/layout/Loader";
import MigraineLineChart from "../../components/analytics/MigraineLineChart";

const DashboardPage = () => {
  const api = useAxios();
  
  const { permisionsList, checkUserPermission } = useContext(PermissionContext);

  useEffect(() => {
    if(permisionsList.length > 0) checkUserPermission(['Analytics-View Dashboard']);
  }, [permisionsList]);

  const dashboardValues = {
    userAuthorityList: [],
    usersWithDoctors: 0,
    usersWithoutDoctors: 0,
    migraineCountThisMonth: 0,
    migraineCountThisYear: 0,
    yearList: [],
  };

  const [apiRes, setApiRes] = useState(resObj);
  const [dashboardData, setDashboardData] = useState(dashboardValues);
  const [selectedYear, setSelectedYear] = useState('');
  const [graphData, setGraphData] = useState([]);
  
  useEffect(() => {
    getDashboardData();
  }, []);

  useEffect(() => {
    if (selectedYear) getMigraineGraph();
  }, [selectedYear]);

  const getDashboardData = async () => {
    try {
      setApiRes({ ...apiRes, loading: true });
      const response = await api.get("/api/analytics/dashboard/data/get");

      if (response.status === 200) {
        setDashboardData({
          ...dashboardData,
          userAuthorityList: response.data.user_authority_list,
          usersWithDoctors: response.data.users_with_doctors,
          usersWithoutDoctors: response.data.users_without_doctors,
          migraineCountThisMonth: response.data.migraine_count_this_month,
          migraineCountThisYear: response.data.migraine_count_this_year,
          yearList: response.data.year_list,
        });
        setSelectedYear(response.data?.year_list[0])
        setApiRes({ ...apiRes, loading: false });
      }
    } catch (err) {
      setApiRes({
        ...apiRes,
        axiosError: true,
        errMsg: JSON.stringify(err.response.data),
        errHeading: "User Type Get",
        loading: false,
      });
    }
  };

  const getMigraineGraph = async () => {
    try {
      const response = await api.get("/api/analytics/migraine/graph/get", {
        params: {
          year: selectedYear,
        }
      });

      if (response.status === 200) {
        setGraphData(response.data);
      }
    } catch (err) {
      setApiRes({
        ...apiRes,
        axiosError: true,
        errMsg: JSON.stringify(err.response.data),
        errHeading: "Migraine Graph Get",
        loading: false,
      });
    }
  };

  return (
    <AutoLogout>
      <MainNavigation />
      {apiRes.loading && <Loader />}
      <div className="mainContainer">
        <TopBar title={"Dashboard"} />

        <Grid container spacing={1}>
          <Grid item xs={6} sm={6} md={3}>
            <BasicCard subTitle={'Patients With Doctors'} content={dashboardData?.usersWithDoctors} />
          </Grid>

          <Grid item xs={6} sm={6} md={3}>
            <BasicCard subTitle={'Patients Without Doctors'} content={dashboardData?.usersWithoutDoctors} />
          </Grid>

          <Grid item xs={6} sm={6} md={3}>
            <BasicCard subTitle={"Migraine count this month"} content={dashboardData?.migraineCountThisMonth} />
          </Grid>

          <Grid item xs={6} sm={6} md={3}>
            <BasicCard subTitle={'Migraine count this year'} content={dashboardData?.migraineCountThisYear} />
          </Grid>
        </Grid>

        <Grid container spacing={1} sx={{ marginTop: 0 }}>
          <Grid item xs={12} lg={5}>
            <Card sx={{
              height: 400,
            }}>
              <CardContent>
                <Box sx={{ textAlign: 'center'}}>
                  <Typography variant="h5" color={'text.secondary'}>
                    <strong>User categories</strong>
                  </Typography>
                </Box>

                <Box sx={{ marginTop: 2, display: 'flex', justifyContent: 'center' }}>
                  <PieChart data={dashboardData?.userAuthorityList} />
                </Box>
              </CardContent>
            </Card>
          </Grid>
          
          <Grid item xs={12} lg={7}>            
            <Card sx={{
              height: 400,
            }}>
              <CardContent>
                <Box sx={{ display: 'flex', justifyContent: 'space-evenly' }}>
                  <Box>
                    <Typography variant="h5" color={'text.secondary'}>
                      <strong>Frequency of migraines</strong>
                    </Typography>
                  </Box>
              
                  <Box>
                    <FormControl>
                      <InputLabel id="yearLabel">Year</InputLabel>
                      <Select
                        labelId="yearLabel"
                        id="year"
                        value={selectedYear}
                        label="Year"
                        onChange={e => setSelectedYear(e.target.value)}
                      >
                        {dashboardData.yearList.map((year, index) => 
                          <MenuItem key={index} value={year}>{year}</MenuItem>
                        )}
                      </Select>
                    </FormControl>
                  </Box>
                </Box>

                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                  <MigraineLineChart data={graphData} />
                </Box>
              </CardContent>
            </Card>
              
          </Grid>
        </Grid>

        <ErrorModal apiRes={apiRes} setApiRes={setApiRes} />
      </div>
    </AutoLogout>
  );
};

export default DashboardPage;