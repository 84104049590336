import { useRef, useEffect } from "react";
import * as d3 from 'd3';
import Box from "@mui/material/Box";

const MigraineLineChart = ({ data }) => {
  const svgRef = useRef();

  useEffect(() => {
    const svg = d3.select(svgRef.current);

    const margin = { top: 20, right: 30, bottom: 30, left: 40 };
    const width = 600 - margin.left - margin.right;
    const height = 300 - margin.top - margin.bottom;

    const tooltip = d3.select("#migraineGraph")
      .append("div")
      .style("opacity", 0)
      .attr("class", "tooltip")
      .style("background-color", "white")
      .style("border", "solid")
      .style("border-width", "2px")
      .style("border-radius", "5px")
      .style("padding", "5px")
      .style("position", "absolute")

    const xScale = d3
      .scaleBand()
      .domain(data.map(d => d.label))
      .range([0, width])
      .padding(0.1);

    const yScale = d3
      .scaleLinear()
      .domain([0, d3.max(data, d => d.value)])
      .range([height, 0]);

    const line = d3
      .line()
      .x(d => xScale(d.label) + xScale.bandwidth() / 2)
      .y(d => yScale(d.value));

    svg.selectAll('*').remove();

    svg
      .append('g')
      .attr('transform', `translate(${margin.left + 15},${margin.top})`)
      .append('path')
      .datum(data)
      .attr('fill', 'none')
      .attr('stroke', '#69b3a2')
      .attr('stroke-width', 2)
      .attr('d', line);

    svg
      .append('g')
      .attr('transform', `translate(${margin.left + 15}, ${height + margin.top})`)
      .call(d3.axisBottom(xScale));

    svg
      .append('g')
      .attr('transform', `translate(${margin.left + 15}, ${margin.top})`)
      .call(d3.axisLeft(yScale));
    
    svg
      .append("g")
      .selectAll("dot")
      .data(data)
      .enter()
      .append("circle")
        .attr('transform', `translate(${margin.right + margin.bottom + 15}, ${margin.top})`)
        .attr("cx", function(d) { return xScale(d.label) } )
        .attr("cy", function(d) { return yScale(d.value) } )
        .attr("r", 5)
        .attr("fill", "#69b3a2")
      .on("mouseover", () => {
        tooltip.style("opacity", 1)
      })
      .on("mousemove", (event, d) => {
        tooltip
          .style('opacity', 1)
          .html(`Month: ${d.label} <br> No of migraines: ${d.value}`)
          .style('left', `${event.pageX + 10}px`)
          .style('top', `${event.pageY - 10}px`);
      })
      .on("mouseout", () => {
        tooltip.style('opacity', 0);
      });
    
    svg
      .append('text')
      .attr('transform', `translate(${width / 2 + margin.left},${height + margin.top + margin.bottom + 5})`)
      .style('text-anchor', 'middle')
      .text('Month');
  
    svg
      .append('text')
      .attr('transform', `rotate(-90) translate(${-height / 2 - margin.top},${margin.left - margin.bottom + 15})`)
      .style('text-anchor', 'middle')
      .text('No of migraines');

  }, [data]);

  return (
    <Box id='migraineGraph'>
      <svg ref={svgRef} width={600} height={320}></svg>
    </Box>
  );
};

export default MigraineLineChart;