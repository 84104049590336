import PropTypes from 'prop-types';

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";

import QuestionnaireItem from "./QuestionnaireItem";

const QuestionnaireHistory = ({ 
  questionnaireList, showAll, toggleShowAll 
}) => {

  return(
    <Box>
      <Grid container spacing={3} style={{ margin: '0px 5px 0px 5px' }}>
        <Grid item xs={4}>
          <Typography 
            component="h5" 
            variant="h5" 
            color={'text.secondary'}
          >
            <strong>Questionnaire - {questionnaireList.length}</strong>
          </Typography>
        </Grid>
      </Grid>

      <Grid container spacing={3} style={{ margin: '-15px 0px 0px 0px' }}>
        <Grid item xs={4}>
          <FormControlLabel
            control={
              <Checkbox
                name='showall'
                value={showAll.current.questionnaire}
                id='showall'
                checked={showAll.current.questionnaire}
                onChange={toggleShowAll}
              />
            }
            label={<i><Typography style={{ color: '#6c757d' }}>Show All</Typography></i>}
          />
        </Grid>
      </Grid>

      <Grid container spacing={3} style={{ marginTop: '-20px', marginLeft: '25px'}}>
        {questionnaireList.map((data, index) => 
          <QuestionnaireItem key={index} data={data} />
        )}
      </Grid>
    </Box>
  );
};

QuestionnaireHistory.propTypes = {
  questionnaireList: PropTypes.array.isRequired,
  showAll: PropTypes.object.isRequired,
  toggleShowAll: PropTypes.func.isRequired,
};

export default QuestionnaireHistory;