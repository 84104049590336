import React, { useState } from "react";
import './filter.css'

const Filter = ({ onFilterChange, disabled, marginLeft }) => {
  const [selectedXAxis, setSelectedXAxis] = useState("age"); // State for X-axis selection
  const [selectedYAxis, setSelectedYAxis] = useState("location"); // State for Y-axis selection

  const handleXAxisChange = (event) => {
    setSelectedXAxis(event.target.value);
    onFilterChange({ x: event.target.value, y: selectedYAxis });
  };

  const handleYAxisChange = (event) => {
    setSelectedYAxis(event.target.value);
    onFilterChange({ x: selectedXAxis, y: event.target.value });
  };

  return (
    <div style={{ marginLeft: marginLeft}}>
      <label>Select X-axis:</label>
      <select value={selectedXAxis} onChange={handleXAxisChange} disabled={disabled}>
       <option value='age'>Age</option>
       <option value='startDate'>Start Date</option>
      </select>

      <label>Select Grouping:</label>
      <select value={selectedYAxis} onChange={handleYAxisChange}>
      <option value='migraine_type'>Migraine Type</option>
       {/* <option value='age'>Age</option> */}
       <option value='location'>Location</option>
       <option value='sex'>Sex</option>
      </select>
    </div>
  );
};

export default Filter;
