import React, { useEffect, useRef, useState } from "react";
import * as d3 from "d3";

const Barchart = ({ rawData, alignment }) => {
  const svgRef = useRef();
  const data1 = [];

  // Iterate through patient data
  rawData.forEach((patient) => {
    const patientInfo = {
      age: patient.age,
      full_name: patient.full_name,
      // migraine_count: patient.migraine_count,
    };

    // Iterate through the patient's migraine records
    patient.migraine_list.forEach((migraine) => {
      const migraineRecord = {
        ...patientInfo,
        endDate: migraine.end_date,
        intensity: migraine.intensity,
        location: migraine.location,
        migraine_type: migraine.migraine_type,
        startDate: migraine.start_date,
      };

      data1.push(migraineRecord);
    });
  });


  const data = data1.map(({ age, intensity }) => ({ age, intensity }));

  useEffect(() => {
    // Clear previous chart
    d3.select(svgRef.current).selectAll("*").remove();

    const locationGroups = d3.group(data, (d) => d.age);
    const locationintensityData = Array.from(
      locationGroups,
      ([age, values]) => ({
        age,
        intensitySum: d3.sum(values, (d) => d.intensity),
      })
    );

    locationintensityData.sort((a, b) => a.age - b.age);

    const intensitygroup = locationintensityData.map(
      (item) => item.intensitySum
    );

    const ageExtent = d3.extent(intensitygroup);
    const minAge = ageExtent[0];
    const maxAge = ageExtent[1];

    const margin = { top: 50, right: 80, bottom: 60, left: 80 };
    const width = 900 - margin.left - margin.right;
    const height = 600 - margin.top - margin.bottom;

    const svg = d3
      .select(svgRef.current)
      .attr("width", width + margin.left + margin.right)
      .attr("height", height + margin.top + margin.bottom)
      .append("g")
      .attr("transform", `translate(${margin.left},${margin.top})`);

    const xScale = d3
      .scaleBand()
      .domain(locationintensityData.map((d) => d.age))
      .range([0, width])
      .padding(0.5);

    const yScale = d3
      .scaleLinear()
      .domain([maxAge, 0])
      .nice()
      .range([0, height]);

    if (alignment === "bar") {
      svg
        .selectAll("rect")
        .data(locationintensityData)
        .enter()
        .append("rect")
        .attr("x", (d) => xScale(d.age))
        .attr("y", (d) => yScale(d.intensitySum))
        .attr("width", xScale.bandwidth())
        .attr("height", (d) => height - yScale(d.intensitySum))
        .attr("fill", "steelblue");
    } else if (alignment === "line") {
      const line = d3
        .line()
        .x((d) => xScale(d.age))
        .y((d) => yScale(d.intensitySum));

      // Draw the line graph
      svg
        .append("path")
        .attr("fill", "none")
        .attr("stroke", "steelblue")
        .attr("stroke-width", 1)
        .attr("d", line(locationintensityData));
      // Code to render a line chart here
    }
    svg
      .append("g")
      .attr('class', 'x-axis')
      .attr("transform", `translate(0,${height})`)
      .call(d3.axisBottom(xScale).tickPadding(8));

    svg
      .append("text")
      .attr("class", "y-axis-title")
      .attr("transform", "rotate(-90)")
      .attr("x", -height / 2)
      .attr("y", -margin.left)
      .attr("dy", "1em")
      .style("text-anchor", "middle")
      .text("Total Number of Migraine");

    svg
      .append("text")
      .attr("class", "x-axis-title")
      .attr("transform", `translate(${width / 2},${height + margin.top})`)
      .style("text-anchor", "middle")
      .text("Age");
    svg
      .selectAll(".x-axis text")
      .attr("transform", "translate(0,1)");
      
    svg.append("g").call(d3.axisLeft(yScale));
  }, [data]);

  return (
    <div>
      <svg ref={svgRef}></svg>
    </div>
  );
};

export default Barchart;
