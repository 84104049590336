import PropTypes from 'prop-types';

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";

import DiaryItem from "./DiaryItem";

const MigraineHistory = ({ diaryList, showAll, toggleShowAll }) => {
  return(
    <Box>
      <Grid container spacing={3} style={{ margin: '0px 5px 0px 5px' }}>
        <Grid item xs={4}>
          <Typography 
            component="h5" 
            variant="h5" 
            color={'text.secondary'}
          >
            <strong>Migraine History - {diaryList.length}</strong>
          </Typography>
        </Grid>
      </Grid>

      <Grid container spacing={3} style={{ margin: '-15px 0px 0px 0px' }}>
        <Grid item xs={4}>
          <FormControlLabel
            control={
              <Checkbox
                name='showall'
                value={showAll.current.diary}
                id='showall'
                checked={showAll.current.diary}
                onChange={toggleShowAll}
              />
            }
            label={<i><Typography style={{ color: '#6c757d' }}>Show All</Typography></i>}
          />
        </Grid>
      </Grid>

      <Grid container spacing={3} style={{ marginTop: '-20px', marginLeft: '25px'}}>
        {diaryList.map((data, index) => 
          <DiaryItem key={index} data={data} />
        )}
      </Grid>
    </Box>
  );
};

MigraineHistory.propTypes = {
  diaryList: PropTypes.array.isRequired,
  showAll: PropTypes.object.isRequired,
  toggleShowAll: PropTypes.func.isRequired,
};

export default MigraineHistory;