import PropTypes from 'prop-types';

import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Divider from '@mui/material/Divider';
import { Box } from "@mui/system";

import styles from "./DiaryItem.module.css";

const TherapyItem = ({ data }) => {
  return(
    <Box id={`box-${data.id}`} className={styles.box}>
      <span className={styles.dot}></span>
      <div className={styles.line}></div>

      <Grid container spacing={3} style={{ marginTop: '10px', marginLeft: '0px' }}>
        <Grid item xs={12}>
          <Typography
            component="h6" 
            variant="h6" 
            color={'text.secondary'}
          >
            <strong><i>{data.datestart} - {data.dateend}</i></strong>
          </Typography>
        </Grid>
        
        <Grid item xs={12}>
          <Typography
            color={'text.secondary'}
          >
            <i>Type: {data.type}</i>
          </Typography>
        </Grid>

        <Grid item xs={4}>
          <Typography
            color={'text.secondary'}
          >
            <i>Duration: {data.duration}</i>
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography
            color={'text.secondary'}
          >
            <i>Current: {data.current}</i>
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography
            color={'text.secondary'}
          >
            <i>Note: {data.note}</i>
          </Typography>
        </Grid>
      </Grid>
      <Divider style={{marginTop: '20px', marginLeft: '6px'}} />
    </Box>
  );
};

TherapyItem.propTypes = {
  data: PropTypes.object.isRequired,
};

export default TherapyItem;