import { useContext } from "react";

import NotificationContext from "../../context/NotificationContext";

import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import NotificationsIcon from '@mui/icons-material/Notifications';
import Badge from '@mui/material/Badge';
import IconButton from '@mui/material/IconButton';
import Tooltip from "@mui/material/Tooltip";
import BarChartIcon from "@mui/icons-material/BarChart";

import SearchBar from "../layout/SearchBar";

const valStyle = {
  fontSize: '40px',
  fontWeight: '600',
};

const FilterHeader = props => {
  const { notificationCount, handleNotificationClick } = useContext(NotificationContext);

  const handleFliterClick = () => {
    props.handleFilter();
  };

  const handleBarchartClick = () => {
    props.getGraphData();
  };

  return (
    <Grid container spacing={1} style={{ marginTop: 5 }}>
      <Grid item xs={12} sm={12} md={4} lg={4.5} xl={7.25}>
        <Typography 
          variant="h4" 
          component="div" 
          color="text.secondary" 
          style={valStyle}
        >
          {props.title}
        </Typography>
      </Grid>

      <Grid item xs={12} sm={12} md={5} lg={4.75} xl={3}>
        <SearchBar pageData={props.pageData} search={props.search} />
      </Grid>

      <Grid item xs={5} sm={5} md={1.5} lg={1.25} xl={0.75}>
        <Button
          variant="contained" 
          color="success"
          onClick={handleFliterClick}
        >
          Filters
        </Button>
      </Grid>

      {(props.isGraphIconVisible && props.GraphIcon) &&
        <Grid item xs={3.5} sm={3.5} md={0.75} lg={0.75} xl={0.5}>
          <Box 
            justifyContent="center"
            alignItems="center"
            sx={{
              marginTop: -1,
            }}
          >
            <Tooltip title="View graph" arrow>
              <IconButton
                size="large"
                color="black"
                style={{
                  margin: "auto",
                  marginLeft: "10px",
                  marginRight: "-15px",
                  cursor: "pointer",
                }}
                onClick={handleBarchartClick}
              >
                <BarChartIcon />
              </IconButton>
            </Tooltip>
          </Box>
        </Grid>
      }

      <Grid item xs={3.5} sm={3.5} md={0.75} lg={0.75} xl={0.5}>
        <Box 
          justifyContent="center"
          alignItems="center"
          sx={{
            marginTop: -1,
          }}
        >
          <Tooltip title="View notifications" arrow>
            <IconButton
              size="large"
              aria-label="show 17 new notifications"
              color="inherit"
              onClick={handleNotificationClick}
            >
              <Badge badgeContent={notificationCount} color="error">
                <NotificationsIcon 
                  color="action" 
                  style={{
                    width: 30,
                    height: 30,
                  }}
                />
              </Badge>
            </IconButton>
          </Tooltip>
        </Box>
      </Grid>
    </Grid>
  );
};

export default FilterHeader;