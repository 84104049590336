import { Fragment } from 'react';
import PropTypes from 'prop-types';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import Select from '@mui/material/Select';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Typography from '@mui/material/Typography';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Divider from '@mui/material/Divider';

import RuleItem from './RuleItem';
import GroupRuleItem from './GroupRuleItem';

import styles from "./QueryForm.module.css";

const style = {
  position: 'absolute',
  top: '300px',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 1100,
  height: 600,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  padding: '20px',
  paddingLeft: '40px',
  overflow: "auto"
};

const QueryForm = ({
  values, pageData, open, setOpen, search, filterDataList, addRule, 
  hanldeDeleteRule, handleToggleGroup, handleChangeRuleDrpDwn, 
  handleChangeValue, handleMainConditionChange, addGroup, addGroupRule, 
  handleChangeGroupRuleDrpDwn, handleChangeGroupValue, hanldeDeleteGroup,
  handleGroupConditionChange, hanldeDeleteGroupRule, clearFilterForm, 
  handleChangeCondition, handleGroupOperatorChange, handleInputChange,
}) => {

  const submitForm = e => {
    pageData.current.pageNumber = 1;
    search();
    handleClose();
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClear = () => {
    clearFilterForm();
  };

  return(
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>  
          <Box sx={style} id='filterForm'>            
            <Grid container spacing={1}>
              <Grid container spacing={1} style={{ margin: '30px 20px 10px 25px'}}>
                <Grid container spacing={3}>
                  <Typography id="title" variant="h5" component="h5" color={'text.secondary'}>
                    <strong>Query Builder</strong>
                  </Typography>
                </Grid>
              </Grid>  

              <Grid container spacing={3} style={{ marginRight: '20px'}}>
                <Grid item xs={3}>
                  <TextField 
                    id="noOfMigraines" 
                    name="noOfMigraines" 
                    label="No of Migraines" 
                    variant="outlined" 
                    type='number' 
                    value={values.noOfMigraines}
                    onChange={handleInputChange('noOfMigraines')}
                    className={styles.filterItem}
                  />
                </Grid>

                <Grid item xs={3}>
                  <FormControl>
                    <InputLabel id="migraineOperator">Migraine Operator</InputLabel>
                    <Select
                      labelId="migraineOperator"
                      id="migraineOperator"
                      name="Migraine Operator"
                      value={values.migraineOperator}
                      label="migraineOperator"
                      onChange={handleInputChange('migraineOperator')}
                      className={styles.filterItem}
                    >
                      <MenuItem value={'lt'}>Less than</MenuItem>
                      <MenuItem value={'lte'}>Less than or equals</MenuItem>
                      <MenuItem value={'gt'}>Greater than</MenuItem>
                      <MenuItem value={'gte'}>Greater than or equals</MenuItem>
                      <MenuItem value={'eq'}>Equals</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={3}>
                  <TextField 
                    id="age" 
                    name="age" 
                    label="Age" 
                    variant="outlined" 
                    type='number' 
                    value={values.age}
                    onChange={handleInputChange('age')}
                    className={styles.filterItem}
                  />
                </Grid>

                <Grid item xs={3}>
                  <FormControl>
                    <InputLabel id="ageOperator">Age Operator</InputLabel>
                    <Select
                      labelId="ageOperator"
                      id="ageOperator"
                      name="ageOperator"
                      value={values.ageOperator}
                      label="Age Operator"
                      onChange={handleInputChange('ageOperator')}
                      className={styles.filterItem}
                    >
                      <MenuItem value={'lt'}>Less than</MenuItem>
                      <MenuItem value={'lte'}>Less than or equals</MenuItem>
                      <MenuItem value={'gt'}>Greater than</MenuItem>
                      <MenuItem value={'gte'}>Greater than or equals</MenuItem>
                      <MenuItem value={'eq'}>Equals</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>

              <Grid container spacing={3} style={{ marginTop: '0px', marginRight: '20px'}}>
                <Grid item xs={3}>
                  <FormControl className={styles.filterItem}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        label='Start Date'
                        name='startDate'
                        value={values.startDate} 
                        onChange={handleInputChange('startDate')} 
                        maxDate={values.today}
                      />
                    </LocalizationProvider>
                  </FormControl>
                </Grid>

                <Grid item xs={3}>
                  <FormControl className={styles.filterItem}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker 
                        label='End Date'
                        name='endDate'
                        value={values.endDate} 
                        minDate={values.startDate} 
                        maxDate={values.today}
                        onChange={handleInputChange('endDate')} 
                      />
                    </LocalizationProvider>
                  </FormControl>
                </Grid>

                <Grid item xs={3}>
                  <FormControl>
                    <InputLabel id="sex">Sex</InputLabel>
                    <Select
                      labelId="sex"
                      id="sex"
                      name="sex"
                      value={values.sex}
                      label="Sex"
                      onChange={handleInputChange('sex')}
                      className={styles.filterItem}
                    >
                      <MenuItem value={'All'}>All</MenuItem>
                      <MenuItem value={'Male'}>Male</MenuItem>
                      <MenuItem value={'Female'}>Female</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>

              <Grid container spacing={3} style={{ marginTop: '0px'}}>
                <Grid item xs={1}>
                  <ToggleButtonGroup
                    color="warning"
                    value={values.mainOperator}
                    exclusive
                    onChange={handleMainConditionChange}
                    aria-label="condition"
                    style={{ height: '37px'}}
                  >
                    <ToggleButton value="AND">AND</ToggleButton>
                    <ToggleButton value="OR">OR</ToggleButton>
                  </ToggleButtonGroup>
                </Grid>

                <Grid item xs={4}>
                  <Button 
                    variant="outlined"
                    style={{ marginLeft: '20px'}}
                    onClick={addGroup}
                  >
                    + Group
                  </Button>
                  <Button 
                    variant="outlined" 
                    color='success'
                    style={{ marginLeft: '10px'}}
                    onClick={addRule}
                  >
                    + Rule
                  </Button>
                </Grid>
              </Grid>

              {values.dynamicList.map((data, index) => (
                <Fragment key={index}>
                  { data.type === 'Group' &&
                    <Fragment key={index}>
                      <Grid container spacing={3} style={{ marginTop: '0px'}} key={index}>
                        <Grid item xs={0.5}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                name={data.name}
                                value={data.checked}
                                id={`inline-${data.index}`}
                                checked={data.checked}
                                onChange={e => handleToggleGroup(data.groupIndex)}
                              />
                            }
                          />
                        </Grid>

                        <Grid item xs={1}>
                          <ToggleButtonGroup
                            color="warning"
                            value={data.operator}
                            exclusive
                            onChange={e => handleGroupOperatorChange(data.groupIndex, e)}
                            aria-label="condition"
                            style={{ height: '37px'}}
                          >
                            <ToggleButton value="AND">AND</ToggleButton>
                            <ToggleButton value="OR">OR</ToggleButton>
                          </ToggleButtonGroup>
                        </Grid>

                        <Grid item xs={1.5}>
                          <Button 
                            variant="outlined" 
                            color='success'
                            style={{ marginLeft: '20px'}}
                            onClick={e => addGroupRule(data.groupIndex)}
                          >
                            + Rule
                          </Button>
                        </Grid>
                        
                        <Grid item xs={1.5} style={{ marginTop: '15px'}}>
                          <Divider />
                        </Grid>

                        <Grid item xs={1} style={{ marginTop: '2px', textAlign: 'center'}}>
                          <Typography id={`title-${index}`} color={'text.secondary'}>
                            <i>Group {data.groupIndex + 1}</i>
                          </Typography>
                        </Grid>

                        <Grid item xs={1.5} style={{ marginLeft: '0px', marginTop: '15px'}}>
                          <Divider />
                        </Grid>

                        <Grid item xs={1.5}>
                          <DeleteOutlineIcon 
                            onClick={e => hanldeDeleteGroup(data.groupIndex)}
                            style={{ cursor: 'pointer'}}
                          />
                        </Grid>
                      </Grid>

                      {data.rules.map((rule, index) => (
                        <GroupRuleItem 
                          rule={rule} 
                          data={data} 
                          key={index} 
                          filterDataList={filterDataList} 
                          handleChangeGroupRuleDrpDwn={handleChangeGroupRuleDrpDwn} 
                          handleChangeGroupValue={handleChangeGroupValue} 
                          handleGroupConditionChange={handleGroupConditionChange} 
                          hanldeDeleteGroupRule={hanldeDeleteGroupRule} 
                        />
                      ))
                      }
                    </Fragment>
                  }

                  { data.type === 'Rule' && <RuleItem 
                                              data={data}
                                              filterDataList={filterDataList} 
                                              handleChangeRuleDrpDwn={handleChangeRuleDrpDwn} 
                                              handleChangeValue={handleChangeValue} 
                                              handleChangeCondition={handleChangeCondition} 
                                              hanldeDeleteRule={hanldeDeleteRule} 
                                            />}
                </Fragment>
              )
              )}

              <Grid container spacing={3} style={{ marginTop: '0px'}}>
                <Grid item xs={1}>
                  <Button variant="outlined" color='secondary' onClick={handleClose}>Close</Button> 
                </Grid>

                <Grid item xs={1}>                
                  <Button variant="outlined" color='error' onClick={handleClear}>Clear</Button>
                </Grid>

                <Grid item xs={1}>
                  <Button 
                    variant="contained" 
                    className='taraPrimaryBtn'
                    onClick={submitForm}
                  >
                    Apply
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Fade>
      </Modal>
    </>
  );
};

QueryForm.propTypes = {
  values: PropTypes.object.isRequired,
  pageData: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  search: PropTypes.func.isRequired,
  filterDataList: PropTypes.array.isRequired,
  addRule: PropTypes.func.isRequired,
  hanldeDeleteRule: PropTypes.func.isRequired,
  handleToggleGroup: PropTypes.func.isRequired,
  handleChangeRuleDrpDwn: PropTypes.func.isRequired,
  handleChangeValue: PropTypes.func.isRequired,
  handleMainConditionChange: PropTypes.func.isRequired,
  addGroup: PropTypes.func.isRequired,
  addGroupRule: PropTypes.func.isRequired,
  handleChangeGroupRuleDrpDwn: PropTypes.func.isRequired,
  handleChangeGroupValue: PropTypes.func.isRequired,
  handleGroupConditionChange: PropTypes.func.isRequired,
  hanldeDeleteGroup: PropTypes.func.isRequired,
  hanldeDeleteGroupRule: PropTypes.func.isRequired,
  clearFilterForm: PropTypes.func.isRequired,
  handleChangeCondition: PropTypes.func.isRequired,
  handleGroupOperatorChange: PropTypes.func.isRequired,
};

export default QueryForm;