import React, { useEffect, useRef } from "react";
import CreateLegend from "./CreateLegend";
import * as d3 from "d3";

const MigraineTypesBarchart = ({ data }) => {
  const svgRef = useRef();

  const color = d3
    .scaleOrdinal()
    .domain(data.map((d) => d.type))
    .range(d3.schemeCategory10);

  useEffect(() => {
    // Clear previous chart
    d3.select(svgRef.current).selectAll("*").remove();

    const margin = { top: 30, right: 20, bottom: 20, left: 60 };
    const width = window.innerWidth * 0.5 - margin.left - margin.right;
    const height = 230 - margin.top - margin.bottom;

    const svg = d3
      .select(svgRef.current)
      .attr("width", width + margin.left + margin.right)
      .attr("height", height + margin.top + margin.bottom)
      .append("g")
      .attr("transform", `translate(${margin.left},${margin.top})`);

    const xScale = d3
      .scaleBand()
      .domain(data.map((d) => d.type))
      .range([0, width])
      .padding(0.7);

    const yScale = d3
      .scaleLinear()
      .domain([0, d3.max(data, (d) => d.count)])
      .nice()
      .range([height, 0]);

    // Add gridlines
    // horizontal grid lines
    svg
      .append("g")
      .attr("class", "grid")
      .attr("transform", `translate(0,0)`)
      .call(
        d3
          .axisLeft(yScale)
          .ticks(5)
          .tickSize(-width) // Extend ticks across the chart width
          .tickFormat("") // Remove tick labels
      )
      .call((g) => g.select(".domain").remove()) // Remove the axis line
      .call((g) =>
        g
          .selectAll(".tick:not(:first-of-type) line")
          .attr("stroke-opacity", 0.5)
          .attr("stroke-dasharray", "2,2")
      ) // Style for grid lines
      .call((g) => g.selectAll(".tick text").remove()); // Ensure text labels are removed

    // vertical grid lines
    svg
      .append("g")
      .attr("class", "grid")
      .call(
        d3
          .axisTop(xScale)
          .tickSize(height) // Extend ticks across the chart height
          .tickFormat("") // Remove tick labels
      )
      .attr("transform", `translate(0,${height})`)
      .call((g) => g.select(".domain").remove()) // Remove the axis line
      .call((g) =>
        g.attr("stroke-opacity", 0.5).attr("stroke-dasharray", "2,2")
      )
      // Style for grid lines
      .call((g) => g.selectAll(".tick text").remove()); // Ensure text labels are removed

    svg
      .selectAll("rect")
      .data(data)
      .enter()
      .append("rect")
      .attr("x", (d) => xScale(d.type))
      .attr("y", (d) => yScale(d.count))
      .attr("width", xScale.bandwidth())
      .attr("height", (d) => height - yScale(d.count))
      .attr("fill", (d) => color(d.type));

    svg
      .append("g")
      .attr("class", "x-axis")
      .attr("transform", `translate(0,${height})`)
      .call(d3.axisBottom(xScale).tickSizeOuter(0))
      .selectAll("text") // Select all text elements of the x-axis
      .remove();

    svg.append("g").attr("class", "y-axis").call(d3.axisLeft(yScale));

    svg
      .append("text")
      .attr("class", "y-axis-title")
      .attr("transform", "rotate(-90)")
      .attr("x", -height / 2)
      .attr("y", -margin.left)
      .attr("dy", "1em")
      .style("text-anchor", "middle");

    svg.selectAll(".y-axis text").attr("dx", "-10");
  }, []);

  return (
    <div>
      <svg ref={svgRef}></svg>
      <CreateLegend
        colorScale={color}
        data={data}
        classname={"flex flex-wrap w-5/6 mx-auto pb-10"}
        displayText="type"
      />
    </div>
  );
};

export default MigraineTypesBarchart;