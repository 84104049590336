import PropTypes from 'prop-types';

import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Divider from '@mui/material/Divider';
import { Box } from "@mui/system";

import styles from "./DiaryItem.module.css";

const QuestionnaireItem = ({ data }) => {

  const getSeverity = (type, score) => {
    let severity = '';

    if (type === 'HIT6') {
      if (score > 0 && score <= 49) {
        severity = 'Little or No Disability';
      } else if (score >= 50 && score <= 55) {
        severity = 'Mild Disability';
      } else if (score >= 56 && score <= 59) {
        severity = 'Moderate Disability';
      } else if (score >= 60 && score <= 78) {
        severity = 'Severe Disability';
      }
    } else if (type === 'PGIC') {

    } else if (type === 'MSQ') {
      if (score > 0 && score <= 19) {
        severity = 'Very severe impact of migraines on quality of life';
      } else if (score >= 29 && score <= 39) {
        severity = 'Severe impact of migraines on quality of life';
      } else if (score >= 40 && score <= 59) {
        severity = 'Moderate impact of migraines on quality of life';
      } else if (score >= 60 && score <= 79) {
        severity = 'Mild impact of migraines on quality of life';
      } else if (score >= 80 && score <= 100) {
        severity = 'Minimal impact of migraines on quality of life';
      }
    } else if (type === 'MIDAS') {
      if (score > 0 && score <= 5) {
        severity = 'Little or No Disability';
      } else if (score > 5 && score <= 10) {
        severity = 'Mild Disability';
      } else if (score > 10 && score <= 20) {
        severity = 'Moderate Disability';
      } else if (score > 21) {
        severity = 'Severe Disability';
      }
    } else if (type === 'PROMIS-29') {

    }

    return severity;
  };

  return(
    <Box id={`box-${data.id}`} className={styles.box}>
      <span className={styles.dot}></span>
      <div className={styles.line}></div>

      <Grid container spacing={3} style={{ marginTop: '10px', marginLeft: '0px' }}>
        <Grid item xs={12}>
          <Typography
            component="h6" 
            variant="h6" 
            color={'text.secondary'}
          >
            <strong><i>{data.datestart} - {data.dateend}</i></strong>
          </Typography>
        </Grid>
      </Grid>
        
      <Grid container spacing={3} style={{ marginTop: '0px', marginLeft: '0px' }}>
        <Grid item xs={6}>
          <Typography
            color={'text.secondary'}
          >
            <i>Type: {data.type}</i>
          </Typography>
        </Grid>

        <Grid item xs={6}>
          <Typography
            color={'text.secondary'}
          >
            <i>Total Score: {data.total_answer_values}</i>
          </Typography>
        </Grid>
      </Grid>

      <Grid container spacing={3} style={{ marginTop: '0px', marginLeft: '0px' }}>
        <Grid item xs={6}>
          <Typography
            color={'text.secondary'}
          >
            <i>Answers: {data.answers.map(item => `${item.answerValues}`).join(', ')}</i>
          </Typography>
        </Grid>

        <Grid item xs={6}>
          <Typography
            color={'text.secondary'}
          >
            <i>Severity: {getSeverity(data.type, data.total_answer_values)}</i>
          </Typography>
        </Grid>
      </Grid>

      <Divider style={{marginTop: '20px', marginLeft: '6px'}} />

    </Box>
  );
};

QuestionnaireItem.propTypes = {
  data: PropTypes.object.isRequired,
};

export default QuestionnaireItem;